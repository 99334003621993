<div class="wrap-view">
  <div class="row m-0" style="margin-top:-3%;">
    <div class="col-12 d-none d-md-block">
      <div class="row">
        <div class="col-md-4" style="padding:0">
          <img src="/assets/images/Chispas 1.png"/>
        </div>
        <div class="col-md-4" style="height:20px;"></div>
        <div class="col-md-4">
          <img src="/assets/images/Chispas 2.png"/>
        </div>
      </div>
    </div>
  </div>
  <div class="company-title">
    <div class="title-maestro">
      <span>Parámetros - Canal</span>
    </div>
    <div class="box-white container-fluid m-1" style="padding: 20px;">
      <label>
        <strong>Filtros</strong>
      </label>
      <form class="form-cliente" autocomplete="offs" [formGroup]="channelQueryForm">
        <div class="container">
          <div class="row m-1">
            <div class="col-2 form-group">
              <label>Descripción: </label>
            </div>
            <div class="col-3">
              <input class="form-control" type="text" id="descp" formControlName="descp"/>
            </div>
            <div class="col-2">
              <label>Mnemónico: </label>
            </div>
            <div class="col-2">
              <input class="form-control" type="text" id="mnemo" formControlName="mnemo"/>
            </div>
            <div class="col-3">
            </div>
          </div>
        </div>
      </form>
      <div class="row m-1">
        <div class="col-12 text-right">
          <button class="form-control" type="button"
                  style="width: 50px;margin-right: 10px;"
                  class="button-general" title="Buscar" (click)="Filtrar()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
                 viewBox="0 0 16 16">
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
            </svg>
          </button>
          <button class="form-control" type="button"
                  style="width: 50px;margin-right: 10px;"
                  class="button-general" title="Limpiar" (click)="Limpiar()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-brush"
                 viewBox="0 0 16 16">
              <path
                d="M15.825.12a.5.5 0 0 1 .132.584c-1.53 3.43-4.743 8.17-7.095 10.64a6.067 6.067 0 0 1-2.373 1.534c-.018.227-.06.538-.16.868-.201.659-.667 1.479-1.708 1.74a8.118 8.118 0 0 1-3.078.132 3.659 3.659 0 0 1-.562-.135 1.382 1.382 0 0 1-.466-.247.714.714 0 0 1-.204-.288.622.622 0 0 1 .004-.443c.095-.245.316-.38.461-.452.394-.197.625-.453.867-.826.095-.144.184-.297.287-.472l.117-.198c.151-.255.326-.54.546-.848.528-.739 1.201-.925 1.746-.896.126.007.243.025.348.048.062-.172.142-.38.238-.608.261-.619.658-1.419 1.187-2.069 2.176-2.67 6.18-6.206 9.117-8.104a.5.5 0 0 1 .596.04zM4.705 11.912a1.23 1.23 0 0 0-.419-.1c-.246-.013-.573.05-.879.479-.197.275-.355.532-.5.777l-.105.177c-.106.181-.213.362-.32.528a3.39 3.39 0 0 1-.76.861c.69.112 1.736.111 2.657-.12.559-.139.843-.569.993-1.06a3.122 3.122 0 0 0 .126-.75l-.793-.792zm1.44.026c.12-.04.277-.1.458-.183a5.068 5.068 0 0 0 1.535-1.1c1.9-1.996 4.412-5.57 6.052-8.631-2.59 1.927-5.566 4.66-7.302 6.792-.442.543-.795 1.243-1.042 1.826-.121.288-.214.54-.275.72v.001l.575.575zm-4.973 3.04.007-.005a.031.031 0 0 1-.007.004zm3.582-3.043.002.001h-.002z"/>
            </svg>
          </button>
          <button class="form-control" type="button" class="button-general"
                  style="width: 50px;margin-right: 10px;" title="Exportar" (click)="exportExcel()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-download" viewBox="0 0 16 16">
              <path
                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
              <path
                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
            </svg>
          </button>
          <button class="form-control" type="button"
                  style="width: 50px;"
                  class="button-general" title="Nuevo Canal" (click)="NewChannelModal()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-file-earmark-plus-fill" viewBox="0 0 16 16">
              <path
                d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM8.5 7v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 1 0z"/>
            </svg>
          </button>
        </div>
      </div>
      <div class="grid-control-custom">
        <ngx-datatable class="material colored-header sm table table-hover"
                       [rows]="rows"
                       [columns]="columns"
                       [columnMode]="'force'"
                       [footerHeight]="40"
                       [headerHeight]="50"
                       [limit]="10"
                       [rowHeight]="40">
        </ngx-datatable>
        <ng-template #actionTemplate let-row="row" let-value="value" let-i="index">
          <div>
            <button class="btn btn-xs" (click)="OpenModal(row)">
              <i class="fa fa-edit" aria-hidden="true" style="color:#B25BC2;" title="Editar"></i>
            </button>
            <button class="btn btn-xs" (click)="OpenQuestionDeleteChannel(row)">
              <i class="fa fa-trash-o" aria-hidden="true" style="color:#B25BC2;" title="Eliminar">
              </i>
            </button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<app-loading *ngIf="loading"></app-loading>
<div class="modal fade" bsModal [config]="{ backdrop: 'static', keyboard: false,animated:false}"
     #channelModal="bs-modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content modal-company-add">
      <div class="modal-header">
        <h5 class="modal-title pull-left">{{titleModal}}</h5>
      </div>
      <div class="modal-body text-left">
        <form class="form-cliente" autocomplete="offs" [formGroup]="channelForm">
          <div class="row">
            <div class="form-group col-sm-12">
              <label class="subtitle-modal">
                Mnemónico</label>
              <input type="text" class="form-control" formControlName="code"/>
            </div>
            <div class="form-group col-sm-12">
              <label class="subtitle-modal">
                Nombre
              </label>
              <input type="text" class="form-control" formControlName="name"/>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer justify-content-evenly">
        <button type="button" class="button-text d-none d-md-block" style="width: 180px !important;"
                title="Cancelar" (click)="closeModal()">Cancelar
        </button>
        <button class="button-general d-none d-md-block" style="width: 180px !important;"
                title="Confirmar" (click)="SaveChannel()">Confirmar
        </button>
        <div class="col-6 m-0 ps-2 d-block d-md-none">
          <button type="button" class="button-text w-100" title="Cancelar"
          >Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" bsModal [config]="{ backdrop: 'static', keyboard: false,animated:false}"
     #ValidaDeleteChannelModal="bs-modal" tabindex="-1">
  <div class="modal-dialog modal-sm" style="top: 211px;">
    <div class="modal-content modal-logout">
      <div class="modal-body text-center">
        <div class="title-logout-modal">¿Está seguro de Eliminar el Registro?</div>
        <div class="row" style="padding-bottom: 22px;">
          <div class="offset-1 col-5">
            <button class="button-logout pull-right" (click)="HideValidateDeleteChannel()">Cancelar</button>
          </div>
          <div class="col-5">
            <button class="button-logout pull-left" (click)="DeleteChannel()">Aceptar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
