<div class="wrap-view">
  <div class="row m-0" style="margin-top:-3%;">
    <div class="col-12 d-none d-md-block">
      <div class="row">
        <div class="col-md-4" style="padding:0">
          <img src="/assets/images/Chispas 1.png" />
        </div>
        <div class="col-md-4" style="height:20px;"></div>
        <div class="col-md-4">
          <img src="/assets/images/Chispas 2.png" />
        </div>
      </div>
    </div>
  </div>
  <div class="company-title">
    <div class="title-maestro">
      <span>Gestión de transferencia de salida - Fraude</span>
    </div>
    <div class="box-white container-fluid m-1" style="padding: 20px;">
      <div class="container" [formGroup]="formFilter">
        <div class="row m-1">
          <div class="col-1 form-group">
            <label>Fecha:</label>
          </div>
          <div class="col-2">
            <input class="form-control" type="text" bsDatepicker formControlName="date" />
          </div>
          <div class="col-1">
            <label>Estado:</label>
          </div>
          <div class="col-2">
            <select class="form-select form-select-sm" formControlName="state">
              <option value="-1">Todos</option>
              <option value="1">Pendiente</option>
              <option value="2">Aprobado</option>
              <option value="3">Rechazado</option>
            </select>
          </div>
          <div class="col-1">
            <label>Tip. Doc.</label>
          </div>
          <div class="col-2">
            <select class="form-select form-select-sm" formControlName="documentType"
              aria-label=".form-select-sm example">
              <option [ngValue]="-1">Todos</option>
              <option *ngFor="let item of listTypeDocument" value="{{item.code}}">
                {{item.description}}</option>
            </select>
          </div>
          <div class="col-2">
            <input class="form-control" type="text" placeholder="NumeroDocumento" formControlName="documentNumber" />
          </div>
          <div class="col-1">
            <button class="form-control" type="button" (click)="search()" class="button-general" title="Buscar">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
                viewBox="0 0 16 16">
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </button>
          </div>
        </div>
        <div class="row m-1">
          <div class="col-10">
          </div>
          <div class="col-1">
            <button class="form-control" type="button" class="button-general" (click)="aprove(rowSelected)"
              title="Aprobar">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-file-earmark-check" viewBox="0 0 16 16">
                <path
                  d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
                <path
                  d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
              </svg>
            </button>
          </div>
          <div class="col-1">
            <button class="form-control" type="button" (click)="decline(rowSelected)" class="button-general"
              title="Rechazar">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-file-earmark-x" viewBox="0 0 16 16">
                <path
                  d="M6.854 7.146a.5.5 0 1 0-.708.708L7.293 9l-1.147 1.146a.5.5 0 0 0 .708.708L8 9.707l1.146 1.147a.5.5 0 0 0 .708-.708L8.707 9l1.147-1.146a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146z" />
                <path
                  d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="grid-control-custom">
        <div>
          <ngx-datatable class="material colored-header sm table table-hover" 
            [rows]="rows" [columns]="columns"
            [columnMode]="'force'" [footerHeight]="40" 
              [headerHeight]="60" [rowHeight]="'auto'" [scrollbarH]="true"
            [selected]="rowSelected"
            [selectionType]="SelectionType.checkbox" [selectAllRowsOnPage]="false"
            [displayCheck]="displayCheck"
            [externalSorting]="true"
            (sort)="onSort($event)"
            (select)="onSelect($event)"></ngx-datatable>
          <ng-template #dateTemplate let-row="row" let-value="value">
            {{row.creationDate|date:'dd/MM/yyyy HH:mm:ss'}}
          </ng-template>
          <ng-template #moneyTemplate let-row="row" let-value="value">
            {{value|number:'1.2-2'}}
          </ng-template>
          <ng-template #estadoTemplate let-row="row" let-value="value">
            <span *ngIf="value===undefined">Pendiente</span>
            <span *ngIf="value===false">Aprobado</span>
            <span *ngIf="value===true">Rechazado</span>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loading *ngIf="loading"></app-loading>
<div class="modal fade" bsModal [config]="{ backdrop: 'static', keyboard: false,animated:false}"
  #ConfirmationModal="bs-modal" tabindex="-1">
  <div class="modal-dialog modal-sm" style="top: 211px;">
    <div class="modal-content modal-logout">
      <div class="modal-body text-center">
        <div class="title-logout-modal">{{titleConfirmationModal}}</div>
        <div class="row" style="padding-bottom: 22px;">
          <div class="offset-1 col-5">
            <button class="button-logout pull-right" (click)="cancelValidateModal()">Cancelar</button>
          </div>
          <div class="col-5">
            <button class="button-logout pull-left" (click)="acceptValidateModal()">Aceptar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>