
<div class="wrap-view">
    <div class="row m-0" style="margin-top:-3%;">
        <div class="col-12 d-none d-md-block">
          <div class="row">
            <div class="col-md-4" style="padding:0">
              <img src="/assets/images/Chispas 1.png" />
            </div>
            <div class="col-md-4" style="height:20px;"></div>
            <div class="col-md-4">
              <img src="/assets/images/Chispas 2.png" />
            </div>
          </div>
        </div>
      </div>


      <div class="company-title">
        <div class="title-maestro">
            <span>Parámetros - Gestión de fraude</span>
        </div>
        <div class="box-white container-fluid m-1" style="padding: 20px;">
            <div class="container">
              <form class="form-cliente" autocomplete="offs" [formGroup]="FraudQueryForm">
                <div class="row m-1">
                  <div class="col-6 form-group">
                    <label>Tiempo de evaluación (en minutos): </label>
                  </div>
                  <div class="col-6">
                    <input class="form-control" type="text" id="evaluaTime" formControlName="evaluaTime" />
                  </div>
                </div>
                <div class="row m-1">
                  <div class="col-6 form-group">
                    <label>Importe mínimo de transferencia: </label>
                  </div>
                  <div class="col-2">
                    <div class="input-group input-group-sm mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-sizing-sm">S/</span>
                      </div>
                      <input type="text" class="form-control"
                             [inputMask]="amountInputMask"
                             formControlName="minamoutPen">
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="input-group input-group-sm mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-sizing-sm">US$</span>
                      </div>
                      <input type="text" class="form-control"
                             [inputMask]="amountInputMask"
                             formControlName="minamoutDoll">
                    </div>
                  </div>
                  <div class="col-2">
                  </div>
                </div>
                <div class="row m-1">
                  <div class="col-6 form-group">
                    <label>Transferencia mismo cliente:</label>
                  </div>
                  <div class="col-6">
                    <input class="form-check-input" type="checkbox" value=""  id="transferSame" formControlName="transferSame">
                  </div>
                </div>
                <div class="row m-1">
                    <div class="col-8 form-group">
                    </div>
                    <div class="col-2">
                        <button class="form-control" type="button" class="button-general" title="Grabar" (click)="Save()">Grabar</button>
                    </div>
                    <div class="col-2">
                        <button class="form-control" type="button" class="button-general" title="Cancelar" (click)="Cancel()">Cancelar</button>
                    </div>
                </div>
              </form>
            </div>
        </div>
      </div>
</div>

<app-loading *ngIf="loading"></app-loading>
