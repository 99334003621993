import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Utilities} from "../shared/helpers/util";
import {PageResult} from "../models/page";

@Injectable({
  providedIn: 'root'
})
export class ConciliationService {

  constructor(private _http: HttpClient) {
  }

  private url: string = environment.url_base;

  getAll(filter: any): Observable<PageResult<any>> {
    const params = Utilities.createHttpParams(filter);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get<PageResult<any>>(`${this.url}/Conciliation/GetFilter`,
      {params, headers: headers});
  }

  getExportContiliation(filter: any): Observable<HttpResponse<Blob>> {
    const params = Utilities.createHttpParams(filter);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}/conciliation/export`,
      {
        headers: headers,
        params: params,
        responseType: 'blob',
        observe: 'response'
      });
  }

}
