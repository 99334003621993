import {Component, OnInit} from '@angular/core';
import {BsLocaleService} from "ngx-bootstrap/datepicker";
import {FormBuilder, FormGroup} from "@angular/forms";
import {FinancialEntityService} from "../../services/financialentity.service";
import {OfficesService} from "../../services/offices.service";
import {finalize, forkJoin, switchMap} from "rxjs";
import {HttpResponse} from "@angular/common/http";
import {Utilities} from "../../shared/helpers/util";
import {TableColumn} from "@swimlane/ngx-datatable/lib/types/table-column.type";

@Component({
  selector: 'app-reg-offices',
  templateUrl: './reg-offices.component.html',
  styleUrls: ['./reg-offices.component.scss']
})
export class RegOfficesComponent implements OnInit {
  loading: boolean = false;
  lastLoadDate: Date = new Date();
  listFinancialEntity: any[] = [];
  listOffice: any[] = [];
  formFilter: FormGroup;
  rows = [];
  columns: TableColumn[] = [];

  constructor(
    private localeService: BsLocaleService,
    private fb: FormBuilder,
    private financialEntityService: FinancialEntityService,
    private officesService: OfficesService
  ) {
    this.formFilter = fb.group({
      entity: [{value: -1, disabled: false}, []],
      office: [{value: -1, disabled: false}, []],
      officeName: [{value: null, disabled: false}, []],
      ubigeo: [{value: null, disabled: false}, []]
    })
  }

  ngOnInit() {
    this.loading = true;
    this.localeService.use('es')
    this.columns = [
      {prop: 'id', name: 'Id', width: 50,},
      {prop: 'entityName', name: 'Entidad', minWidth: 350, cellClass: 'text-left'},
      {prop: 'officeId', name: 'Oficina', width: 50},
      {prop: 'ubigeo', name: 'Ubigeo Plaza', width: 80},
      {prop: 'name', name: 'Nombre', cellClass: 'text-left'},
      {prop: 'address', name: 'Domicilio', cellClass: 'text-left'},
      {prop: 'location', name: 'Localidad', cellClass: 'text-left'}];

    forkJoin({
      financialEntity: this.financialEntityService.GetALL(),
      offices: this.officesService.GetAllFiltered({})
    })
      .pipe(finalize(() => this.loading = false))
      .subscribe(x => {
        this.listFinancialEntity = x.financialEntity;
        this.rows = x.offices;
      });
  }

  search(): void {
    this.loading = true;
    const filter = this.formFilter.getRawValue();
    if (filter.entity == -1) filter.entity = null;
    if (filter.office == -1) filter.office = null;

    this.officesService.GetAllFiltered(filter)
      .pipe(finalize(() => this.loading = false))
      .subscribe(x => this.rows = x);

  }

  uploadOfficeFile(event: Event): void {
    const target = event.target as HTMLInputElement;
    const fileList: FileList | null = target.files;
    if (fileList && fileList.length > 0) {
      const file: File = fileList[0];
      this.loading = true;
      this.officesService.uploadOfficeFile(file)
        .pipe(
          switchMap(() => {
            const filter = this.formFilter.getRawValue();
            if (filter.entity == -1) filter.entity == null;
            if (filter.office == -1) filter.office == null;

            return this.officesService.GetAllFiltered(filter);
          }),
          finalize(() => this.loading = false))
        .subscribe((response) => {
          this.rows = response;
        });
    }
  }

  downloadExcel(): void {
    this.loading = true;

    const filter = this.formFilter.getRawValue();
    if (filter.entity == -1) filter.entity = null;
    if (filter.office == -1) filter.office = null;

    this.officesService.GetReportExcel(filter)
      .pipe(finalize(() => this.loading = false))
      .subscribe((response: HttpResponse<Blob>) => {
        Utilities.downloadBlobFile(response.body as Blob, Utilities.getFileNameResponse(response));
      });
  }
}
