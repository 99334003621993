import {HttpParams, HttpResponse} from "@angular/common/http";
export class Utilities {

  public static createHttpParams(params: {}): HttpParams {
    let httpParams: HttpParams = new HttpParams();
    Object.keys(params).forEach(param => {
      if (params[param]) {
        if(params[param] instanceof Date) {
          var date = new Date(params[param]);
          httpParams = httpParams.set(param,date.toString().substring(0,24))
        }
       else httpParams = httpParams.set(param, params[param]);
      }
    });

    return httpParams;
  }

  public static downloadBlobFile(blob: Blob , nombre: string): void {
    this.downloadUrlDataFile(window.URL.createObjectURL(blob), nombre);
  }

  public  static  getFileNameResponse(response:HttpResponse<Blob>):string{
    const disposition = response.headers.get('content-disposition');
    let filename = '';
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return  filename;
  }
  public static downloadUrlDataFile(url: string, nombre: string): void {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', nombre);
    document.body.appendChild(link);
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
    link.remove();
    window.URL.revokeObjectURL(link.href);
  }
}
