import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Utilities} from "../shared/helpers/util";

@Injectable({
  providedIn: 'root'
})
export class CommissionService {

  constructor(private _http: HttpClient) {
  }

  private url: string = environment.url_base;

  GetALL(filter: any): Observable<any> {
    const params = Utilities.createHttpParams(filter);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}/Commisions`, {params, headers: headers});
  }


  getExportExcel(): Observable<HttpResponse<Blob>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}/Commisions/download`,
      {
        headers: headers,
        responseType: 'blob',
        observe: 'response'
      });
  }


  registrar(datos: any): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(`${this.url}/Commisions`, datos, {
      headers: headers
    });
  }

  modificar(datos: any): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(`${this.url}/Commisions`, datos, {
      headers: headers
    });
  }


  eliminar(id: any): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.delete(`${this.url}/Commisions/${id}`, {headers: headers});
  }
}
