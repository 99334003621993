import { Component, OnInit, ViewChild } from '@angular/core';
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { LimitchanneloperationService } from 'src/app/services/limitchanneloperation.service';
import { OperationtypeService } from 'src/app/services/operationtype.service';
import { PlazatypeService } from 'src/app/services/plazatype.service';
import { ChannelService } from 'src/app/services/channel.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize, forkJoin } from "rxjs";
import { HttpResponse } from "@angular/common/http";
import { Utilities } from "../../shared/helpers/util";
import { Page } from "../../models/page";
import { createMask } from "@ngneat/input-mask";
import { AppConstants, MASK_AMOUNT } from "../../shared/constants/app.constants";
import { ComissionTypeService } from "../../services/comission-type.service";
import { CurrencyService } from "../../services/currency.service";
import { ClientTypeService } from "../../services/client-type.service";
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-reg-limit-channels',
  templateUrl: './reg-limit-channels.component.html',
  styleUrls: ['./reg-limit-channels.component.scss']
})
export class RegLimitChannelsComponent implements OnInit {

  page: Page;
  @ViewChild('ValidaDeleteChannelModal') ValidaDeleteChannelModal: ModalDirective;
  @ViewChild('limitforchannelModal') limitforchannelModal: ModalDirective;

  amountInputMask = createMask(MASK_AMOUNT);
  limitchannelsForm: FormGroup = new FormGroup({});
  titleModal: string = "";

  rows = [] as any[];
  columns = [] as any[];
  deleteRow: any;

  listOperationType = [] as any[];
  listPlazaType = [] as any[];
  listChannel = [] as any[];
  listLimitChannel = [] as any[];
  isAllDataAvailable = false;
  loading: boolean = false;

  ActivePrincipal: boolean = false;
  minDate: Date = new Date();

  constructor(
    private alertService: AlertService,
    private localeService: BsLocaleService,
    private limitchanneloperationService: LimitchanneloperationService,
    private operationtypeService: OperationtypeService,
    private plazatypeService: PlazatypeService,
    private channelService: ChannelService,
    private formBuilder: FormBuilder
  ) {

  }

  ngOnInit() {
    this.titleModal = "Nuevo Límites por canal";
    this.page = {
      size: 10,
      pageNumber: 1,
      totalElements: 0,
      totalPages: 0
    };
    this.ChargeData();

    this.limitchannelsForm = this.formBuilder.group({
      id: [{ value: null, disabled: true }, []],
      principal: [{ value: false, disabled: false }, []],
      operationTypeCode: [{ value: -1, disabled: false }, []],
      operationTypeName: [{ value: null, disabled: false }, []],
      channelCode: [{ value: -1, disabled: false }, []],
      channelName: [{ value: null, disabled: false }, []],
      limitPen: [{ value: null, disabled: false }, []],
      limitDollar: [{ value: null, disabled: false }, []],
      effectiveStartDate: [{ value: null, disabled: false }, []],
      effectiveEndDate: [{ value: null, disabled: false }, []],
      description: [{ value: '', disabled: false }, []],
      scope: [{ value: 1, disabled: false }, []]
    });

  }

  ChargeData(): void {
    this.loading = true;

    const filter = {} as any;
    filter.pageNumber = this.page.pageNumber;
    filter.pageSize = this.page.size;

    forkJoin({
      operationtypes: this.operationtypeService.GetALL(),
      plazatypes: this.plazatypeService.GetALL(),
      channels: this.channelService.GetALL(),
      limitchannel: this.limitchanneloperationService.GetALL(filter)
    })
      .pipe(finalize(() => this.loading = false))
      .subscribe(req => {
        this.listOperationType = req.operationtypes;
        this.listPlazaType = req.plazatypes;
        this.listChannel = req.channels;

        this.rows = [...req.limitchannel.rows];
        this.page.totalElements = req.limitchannel.totalRows;
        this.page.totalPages = req.limitchannel.maxPage;

        this.isAllDataAvailable = true;
      });
  }

  OpenQuestionDeleteRecord(row: any = 0): void {
    if (row.principal && !row.effectiveEndDate) {
      this.alertService.showMessage("Eliminar Limite", "No se puede eliminar este limite.", MessageSeverity.error);
    } else {
      this.deleteRow = row;
      this.ValidaDeleteChannelModal.show();
    }
  }

  EditRecord(row: any): void {
    if (row.effectiveStartDate)
      row.effectiveStartDate = new Date(row.effectiveStartDate + 'Z');

    if (row.effectiveEndDate)
      row.effectiveEndDate = new Date(row.effectiveEndDate + 'Z');

    this.limitchannelsForm.patchValue(row);
    this.limitforchannelModal.show();
    this.onChangePrincipal();
  }

  getData(): void {
    this.loading = true;
    const filter = {} as any;
    filter.pageNumber = this.page.pageNumber;
    filter.pageSize = this.page.size;
    this.limitchanneloperationService.GetALL(filter)
      .pipe(finalize(() => this.loading = false))
      .subscribe(req => {
        this.rows = [...req.rows];
        this.page.totalElements = req.totalRows;
        this.page.totalPages = req.maxPage;
      })
  }

  setPage(pageInfo: any): void {
    this.page.pageNumber = pageInfo.offset +1;
    this.getData();
  }

  exportExcel(): void {
    this.loading = true;
    this.limitchanneloperationService.getExportExcel()
      .pipe(finalize(() => this.loading = false))
      .subscribe((response: HttpResponse<Blob>) => {
        Utilities.downloadBlobFile(response.body as Blob, Utilities.getFileNameResponse(response));
      });
  }

  NewReg(): void {
    this.limitforchannelModal.show();
    this.limitchannelsForm.controls['id'].setValue(0);
    this.onChangePrincipal();
  }

  HideValidateDeleteChannel(): void {
    this.ValidaDeleteChannelModal.hide();
  }

  DeleteChannel(): void {
    this.loading = true;
    this.limitchanneloperationService.delete(this.deleteRow.id)
      .pipe(finalize(() => this.loading = false))
      .subscribe(x => {
        this.getData();
        this.HideValidateDeleteChannel();
      })
  }

  closeModal(): void {
    this.limitforchannelModal.hide();
    this.limitchannelsForm.controls['id'].setValue(null);
    this.limitchannelsForm.controls['principal'].setValue(false);
    this.limitchannelsForm.controls['operationTypeCode'].setValue(-1);
    this.limitchannelsForm.controls['operationTypeName'].setValue(null);
    this.limitchannelsForm.controls['channelCode'].setValue(-1);
    this.limitchannelsForm.controls['channelName'].setValue(null);
    this.limitchannelsForm.controls['limitPen'].setValue(null);
    this.limitchannelsForm.controls['limitDollar'].setValue(null);
    this.limitchannelsForm.controls['effectiveStartDate'].setValue(null);
    this.limitchannelsForm.controls['effectiveEndDate'].setValue(null);
    this.limitchannelsForm.controls['description'].setValue(null);
    this.limitchannelsForm.controls['scope'].setValue(1);
    this.onChangePrincipal();
  }

  SaveLimiForchannel(): void {
    const value = this.limitchannelsForm.getRawValue();
    this.loading = true;

    if (value.limitPen == null){
      value.limitPen = 0;
    }

    if (value.limitDollar == null){
      value.limitDollar = 0;
    }

    if (value.operationTypeCode === -1)
      value.operationTypeCode = null;
    if (value.channelCode === -1)
      value.channelCode = null;

    if (value.id > 0) {
      this.limitchanneloperationService.modify(value)
        .pipe(finalize(() => this.loading = false))
        .subscribe(x => {
          this.getData();
          this.closeModal();
        })
    } else {
      this.limitchanneloperationService.create(value)
        .pipe(finalize(() => this.loading = false))
        .subscribe(x => {
          this.getData();
          this.closeModal();
        })
    }
  }

  onChangePrincipal(): void {
    this.ActivePrincipal = this.limitchannelsForm.value['principal']
    if (this.ActivePrincipal) {
      this.limitchannelsForm.controls['channelCode'].setValue(-1);
      this.limitchannelsForm.controls['channelCode'].disable();
      // this.limitchannelsForm.controls['effectiveStartDate'].disable();
      // this.limitchannelsForm.controls['effectiveEndDate'].disable();
    } else {
      this.limitchannelsForm.controls['channelCode'].setValue(-1);
      this.limitchannelsForm.controls['channelCode'].enable();
      // this.limitchannelsForm.controls['effectiveStartDate'].enable();
      // this.limitchannelsForm.controls['effectiveEndDate'].enable();
    }
  }

}
