import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChannelService {

  constructor(private _http: HttpClient) { }

  private url: string = environment.url_base;

  GetALL(): Observable<any> {
    return this._http.get(`${this.url}/Channel`);
  }

  FilterAll(data:any): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(`${this.url}/Channel/FilterChannels`, params, { headers: headers });
  }

  Register(data:any): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(`${this.url}/Channel/Register`, data, { headers: headers });
  }

  Modify(data:any): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(`${this.url}/Channel`, data, { headers: headers });
  }

  Delete(code:string): Observable<any> {
    return this._http.delete(`${this.url}/Channel/${code}`);
  }

  getExportExcel(): Observable<HttpResponse<Blob>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}/Channel/download`,
      {
        headers: headers,
        responseType: 'blob',
        observe: 'response'
      });
  }
}
