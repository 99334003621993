import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Utilities} from "../shared/helpers/util";

@Injectable({
  providedIn: 'root'
})
export class OfficesService {
  private url: string = environment.url_base;

  constructor(private _http: HttpClient) {
  }

  GetAllFiltered(filter: any): Observable<any> {
    const params = Utilities.createHttpParams(filter);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}/offices`, {
      params,
      headers
    });
  }

  GetReportExcel(filter: any): Observable<HttpResponse<Blob>> {
    const params = Utilities.createHttpParams(filter);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}/offices/download`,
      {
        headers: headers,
        params: params,
        responseType: 'blob',
        observe: 'response'
      });
  }

  uploadOfficeFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append("file", file, file.name);
    return this._http.post(`${this.url}/offices/upload`, formData);
  }
}
