import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenModel } from '../models/security';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private urlBase: string = environment.url_base_onboarding;

  constructor(private _http: HttpClient, private router: Router) {}

  public GetAuthTokenAsync(username: string, password: string ): Observable<TokenModel> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json'
      })
    };
    return this._http.post<TokenModel>(`${this.urlBase}/identity/connect/token`,
    `client_id=tinadmin&grant_type=mysqldb&username=${username}&password=${password}`,
    httpOptions);
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(['/login'], { queryParams: { returnUrl: '' } });
  }



  
  // SECURITY
  getChangePassword(): Observable<any> {
    return this._http.get(`${this.urlBase}/security/user/change-password`);
  }

  saveChangePassword(data: any): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(
      this.urlBase + `/security/user/change-password`,
      params,
      { headers: headers }
    );
  }
}
