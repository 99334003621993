<div class="wrap-view">
  <div class="row m-0" style="margin-top:-3%;">
    <div class="col-12 d-none d-md-block">
      <div class="row">
        <div class="col-md-4" style="padding:0">
          <img src="/assets/images/Chispas 1.png" />
        </div>
        <div class="col-md-4" style="height:20px;"></div>
        <div class="col-md-4">
          <img src="/assets/images/Chispas 2.png" />
        </div>
      </div>
    </div>
  </div>
  <div class="company-title">
    <div class="title-maestro">
      <span>Conciliación con T24</span>
    </div>
    <div class="box-white container-fluid m-1" style="padding: 20px;">
      <form class="form-cliente" autocomplete="offs" [formGroup]="conciliationQueryForm">
        <label>
          <strong>Filtros</strong>
        </label>
        <div class="container">
          <div class="row m-1">
            <div class="col-3 form-group">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                (change)="onChangeRadio1($event)" checked>
              <label style="margin-left: 10px;" for="flexRadioDefault1">Fecha de liquidacion</label>
            </div>
          </div>
          <div class="row m-1">
            <div class="col-3 form-group">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                (change)="onChangeRadio2($event)">
              <label style="margin-left: 10px;" for="flexRadioDefault2">Fecha de transferencia</label>
            </div>
            <div class="col-2 form-group" *ngIf="isFechaLiquiFilter">
              <input type="text" bsDatepicker id="FechaLiqui" class="form-control" formControlName="liquidationDate"
                autocomplete="off" />
            </div>
            <div class="col-1 form-group" *ngIf="!isFechaLiquiFilter">
              <label>Desde</label>
            </div>
            <div class="col-2" *ngIf="!isFechaLiquiFilter">
              <input type="text" bsDatepicker class="form-control" id="FechDesde" formControlName="startTransferDate" />
            </div>
            <div class="col-1" *ngIf="!isFechaLiquiFilter">
              <label>Hasta</label>
            </div>
            <div class="col-2" *ngIf="!isFechaLiquiFilter">
              <input type="text" bsDatepicker formControlName="endTransferDate" class="form-control" id="FechHasta" />
            </div>
          </div>
          <div class="row m-1">
            <div class="col-3 form-group">
              <label>Estado de conciliación</label>
            </div>
            <div class="col-2">
              <select class="form-select form-select-sm" aria-label=".form-select-sm example" id="estadoConci"
                formControlName="state">
                <option value="-1">Todos</option>
                <option [value]="1" >Conciliado</option>
                <option [value]="2" >No Conciliado</option>
              </select>
            </div>
            <div class="col-2">
              <label>Tipo de transferencia</label>
            </div>
            <div class="col-3">
              <select class="form-select form-select-sm" aria-label=".form-select-sm example" id="tipoDeTran"
                formControlName="transferType">
                <option value="-1">Todos</option>
                <option value="1">Entrada</option>
                <option value="2">Salida</option>
              </select>
            </div>
          </div>
          <div class="row m-1">
            <div class="col-3">
              <label>Referencia</label>
            </div>
            <div class="col-3">
              <input class="form-control" type="text" id="ref" formControlName="reference" />
            </div>
            <div class="col-1 form-group">
              <label>CCI</label>
            </div>
            <div class="col-2">
              <select class="form-select form-select-sm" aria-label=".form-select-sm example" id="cCIOri"
                formControlName="typeCCI">
                <option value="-1">Seleccionar</option>
                <option value="1">Originante</option>
                <option value="2">Beneficiario</option>
              </select>
            </div>
            <div class="col-3">
              <input class="form-control" type="text" formControlName="cCI" />
            </div>
          </div>
          <div class="row m-1">
            <div class="col-12" style="text-align: right;">
              <button class="form-control" style="width: 50px;margin-right: 10px;" type="button" class="button-general"
                title="Buscar" (click)="getConciliation()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
                  viewBox="0 0 16 16">
                  <path
                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </button>
              <button class="form-control" style="width: 50px;margin-right: 10px;" type="button" class="button-general"
                title="Limpiar" (click)="Clear()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-brush"
                  viewBox="0 0 16 16">
                  <path
                    d="M15.825.12a.5.5 0 0 1 .132.584c-1.53 3.43-4.743 8.17-7.095 10.64a6.067 6.067 0 0 1-2.373 1.534c-.018.227-.06.538-.16.868-.201.659-.667 1.479-1.708 1.74a8.118 8.118 0 0 1-3.078.132 3.659 3.659 0 0 1-.562-.135 1.382 1.382 0 0 1-.466-.247.714.714 0 0 1-.204-.288.622.622 0 0 1 .004-.443c.095-.245.316-.38.461-.452.394-.197.625-.453.867-.826.095-.144.184-.297.287-.472l.117-.198c.151-.255.326-.54.546-.848.528-.739 1.201-.925 1.746-.896.126.007.243.025.348.048.062-.172.142-.38.238-.608.261-.619.658-1.419 1.187-2.069 2.176-2.67 6.18-6.206 9.117-8.104a.5.5 0 0 1 .596.04zM4.705 11.912a1.23 1.23 0 0 0-.419-.1c-.246-.013-.573.05-.879.479-.197.275-.355.532-.5.777l-.105.177c-.106.181-.213.362-.32.528a3.39 3.39 0 0 1-.76.861c.69.112 1.736.111 2.657-.12.559-.139.843-.569.993-1.06a3.122 3.122 0 0 0 .126-.75l-.793-.792zm1.44.026c.12-.04.277-.1.458-.183a5.068 5.068 0 0 0 1.535-1.1c1.9-1.996 4.412-5.57 6.052-8.631-2.59 1.927-5.566 4.66-7.302 6.792-.442.543-.795 1.243-1.042 1.826-.121.288-.214.54-.275.72v.001l.575.575zm-4.973 3.04.007-.005a.031.031 0 0 1-.007.004zm3.582-3.043.002.001h-.002z" />
                </svg>
              </button>
              <button class="form-control" style="width: 50px;" type="button" class="button-general" title="export"
                (click)="downloadExcel()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-download" viewBox="0 0 16 16">
                  <path
                    d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path
                    d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </form>
      <form class="form-cliente" autocomplete="offs">
        <div class="grid-control-custom">
          <ngx-datatable class="material colored-header sm table table-hover" [rows]="rows" [columnMode]="'force'"
            [footerHeight]="40" [headerHeight]="60" [rowHeight]="35" #table [externalPaging]="true"
            [scrollbarH]="true"
            [externalSorting]="true"
            (sort)="onSort($event)"
             [count]="page.totalElements??0"
              [offset]="page.pageNumber??0" [limit]="page.size" (page)="setPage($event)"
            [messages]="{ emptyMessage: 'No records found', totalMessage: 'total' }">

            <ngx-datatable-column name="F. Liquidación" [width]="100" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.settlementDate|date:"dd/MM/yyyy"}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Tip. Transf." [width]="100" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.transferType}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Estado" [width]="150" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.state}}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="F/H. Transferencia" [width]="200" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.creationDate|date:"dd/MM/yyyy"}} {{row.creationTime}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Ent. Origen" [width]="100" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.originFinancialEntityCode}}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="CCI Origen" [width]="200" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.cciOrigin}}</span>
              </ng-template>
            </ngx-datatable-column>


            <ngx-datatable-column name="Ent. Benef." [width]="100" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.destinationFinancialEntityCode}}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="CCI Benef." [width]="200" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.cciDestination}}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Moneda" [width]="100" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.currency}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Imp. Transf." [width]="100" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.amount|number:'1.2-2'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Com. CCE" [width]="100" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.feeAmount|number:'1.2-2'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Com. BRIP" [width]="100" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.feeAmountBank|number:'1.2-2'}}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Trace" [width]="150" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.trace}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="InstructionId Con" [width]="250" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.queryInstructionId}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="InstructionId OT" [width]="250" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.instructionId}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Ind. Cobro. Com." [width]="100" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.unstructuredInformation}}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
                <div class="page-count">{{ rowCount.toLocaleString() }} elements</div>
                <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'" [page]="page.pageNumber??0" [size]="page.size??0"
                  [count]="page.totalElements??0" class="datatable-pager" (change)="table.onFooterPage($event)">
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
        <div class="row m-1">
          <div class="col-6">
          </div>
          <div class="col-1">
            <label>Total S/</label>
          </div>
          <div class="col-2">
            <input class="form-control text-right" type="text" id="lbsoles" readonly value="{{soles|number:'1.2-2'}}" />
          </div>
          <div class="col-1 text-right">
            <label>Total $</label>
          </div>
          <div class="col-2 text-right">
            <input class="form-control text-right" type="text" id="lbdolare" readonly
              value="{{dolares|number:'1.2-2'}}" />
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<app-loading *ngIf="loading"></app-loading>