import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Utilities} from "../shared/helpers/util";

@Injectable({
  providedIn: 'root'
})
export class TransferOrderService {

  private url: string = environment.url_base;

  constructor(private _http: HttpClient) {
  }

  GetALL(filter): Observable<any> {
    const params = Utilities.createHttpParams(filter);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}/TransferOrder`, {
      params,
      headers
    });
  }

  getExport(filter: any): Observable<HttpResponse<Blob>> {
    const params = Utilities.createHttpParams(filter);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}/TransferOrder/export`,
      {
        headers: headers,
        params: params,
        responseType: 'blob',
        observe: 'response'
      });
  }

}
