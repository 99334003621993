import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DocumentTypeService } from '../../services/documenttype.service';
import { finalize, forkJoin, Observable } from 'rxjs';
import { TransferOrderOutService } from '../../services/transferorderout.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SelectionType } from '@swimlane/ngx-datatable';
import { Page } from 'src/app/models/page';

@Component({
  selector: 'app-managment-fraud',
  templateUrl: './managment-fraud.component.html',
  styleUrls: ['./managment-fraud.component.scss'],
})
export class ManagmentFraudComponent implements OnInit {
  listTypeDocument: any[] = [];
  formFilter: FormGroup;
  loading: boolean = false;
  page: Page;
  titleConfirmationModal: string = '';
  aproveConfirmationModal: boolean = true;
  rowsAproved: any[] | null = [];
  rows = [];
  columns: any[] = [];
  declineConfirmationModal: boolean = false;
  rowsDecline: any[] | null = [];
  rowSelected: any[] = [];
  SelectionType = SelectionType;
  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<any>;
  @ViewChild('estadoTemplate', { static: true }) estadoTemplate: TemplateRef<any>;
  @ViewChild('moneyTemplate', { static: true }) moneyTemplate: TemplateRef<any>;

  @ViewChild('ConfirmationModal') ConfirmationModal: ModalDirective;

  constructor(
    private fb: FormBuilder,
    private transferOrderOutService: TransferOrderOutService,
    private documentTypeService: DocumentTypeService
  ) {
    this.formFilter = fb.group({
      date: [{ value: new Date(), disabled: false }, []],
      state: [{ value: 1, disabled: false }, []],
      documentType: [{ value: -1, disabled: false }, []],
      documentNumber: [{ value: null, disabled: false }, []],
    });
  }

  ngOnInit() {
    this.loading = true;

    this.columns = [
      {
        name: '',
        width: 30,
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizeable: false,
        headerCheckboxable: false,
        checkboxable: true,
      },
      { name: 'Fecha/hora', width: 180, cellTemplate: this.dateTemplate },
      { prop: 'fraud', name: 'Estado', width: 100, cellTemplate: this.estadoTemplate },
      { prop: 'financialEntityCode', name: 'Ent. Dest', width: 80 },
      // { prop: 'instructionId', name: 'Reference ID OT', width: 250 },
      { prop: 'trace', name: 'Trace', width: 150 },
      { prop: 'cciOrigin', name: 'CCI Origen', width: 200 },
      { prop: 'cciDestination', name: 'CCI Destino', width: 200 },
      { prop: 'currency', name: 'Moneda', width: 100 },
      {
        prop: 'amount',
        name: 'Importe',
        width: 100,
        cellTemplate: this.moneyTemplate,
      },
      {
        prop: 'feeAmount',
        name: 'Com. CC',
        width: 100,
        cellTemplate: this.moneyTemplate,
      },
      {
        prop: 'feeAmountBank',
        name: 'Com. Bank',
        width: 100,
        cellTemplate: this.moneyTemplate,
      },
      { prop: 'originDocumentNumber', name: 'Doc. Id', width: 150 },
      { prop: 'originName', name: 'Originante', width: 250 },
      { prop: 'destinyDocumentNumber', name: 'Doc. Id', width: 150 },
      { prop: 'destinyName', name: 'Beneficiario', width: 250 },
      { prop: 'referenceInstructionId', name: 'Reference ID Con.', width: 250 },
    ];

    
    this.page = {
      sort:'',
      sortType:''
    };

    forkJoin({
      documentTypes: this.documentTypeService.GetALL(),
      data: this.searchObs(),
    })
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((x) => {
        this.listTypeDocument = x.documentTypes;
        this.rows = x.data;
      });
  }

  search(sortInfo: any = null): void {
    this.loading = true;
    this.searchObs(sortInfo)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((x) => {
        this.rows = x;
      });
  }

  searchObs(sortInfo: any = null): Observable<any> {
    const filter = this.formFilter.getRawValue();
    if (filter.documentType == -1) filter.documentType = null;
    if (filter.state == -1) filter.state = null;
    
    if (sortInfo == null) {
      filter.order = this.page.sort;
      filter.orderType = this.page.sortType;
    } else {
      filter.order = sortInfo.prop;
      filter.orderType = sortInfo.dir;
    }
    return this.transferOrderOutService.GetALLForFraud(filter);
  }

  aprove(row): void {
    this.titleConfirmationModal =
      '¿Está seguro de aprobar las transacciones seleccionadas?';
    this.declineConfirmationModal = false;
    this.aproveConfirmationModal = true;
    this.rowsDecline = null;
    this.rowsAproved = this.rowSelected;
    this.ConfirmationModal.show();
  }

  decline(row): void {
    this.titleConfirmationModal =
      '¿Está seguro de rechazar las transacciones seleccionadas?';
    this.declineConfirmationModal = true;
    this.aproveConfirmationModal = false;
    this.rowsDecline = this.rowSelected;
    this.rowsAproved = null;
    this.ConfirmationModal.show();


  }

  acceptValidateModal(): void {
    if (this.aproveConfirmationModal && !this.declineConfirmationModal) {
      if (this.rowsAproved && this.rowsAproved.length > 0) {
        this.loading = true;
        forkJoin(
          this.rowsAproved.map((x) => this.transferOrderOutService.aprove(x))
        )
          .pipe(finalize(() => (this.loading = false)))
          .subscribe((x) => {
            this.searchObs().subscribe((z) => (this.rows = z));
            this.ConfirmationModal.hide();
            this.rowsDecline = null;
            this.rowsAproved = null;
            this.rowSelected = [];
          });
      }
    }
    if (!this.aproveConfirmationModal && this.declineConfirmationModal) {
      if (this.rowsDecline && this.rowsDecline.length > 0) {
        this.loading = true;
        forkJoin(
          this.rowsDecline.map((x) => this.transferOrderOutService.decline(x))
        )
          .pipe(finalize(() => (this.loading = false)))
          .subscribe((x) => {
            this.searchObs().subscribe((z) => (this.rows = z));
            this.ConfirmationModal.hide();
            this.rowsDecline = null;
            this.rowsAproved = null;
            this.rowSelected = [];
          });
      }
    }
  }

  cancelValidateModal(): void {
    this.ConfirmationModal.hide();
  }

  displayCheck(row) {
    return row.fraud !== true && row.fraud !== false;
  }
  onSelect({ selected }) {
    this.rowSelected.splice(0, this.rowSelected.length);
    this.rowSelected.push(...selected);
  }

  onSort(event) {
    const sort = event.sorts[0];
    this.page.sort = sort.prop;
    this.page.sortType = sort.dir;
    this.search(sort);
  }

}
