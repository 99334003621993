import {Component, OnInit, ViewChild} from '@angular/core';
import {finalize, forkJoin} from 'rxjs';
import {CommissionService} from 'src/app/services/commission.service';
import {PlazatypeService} from 'src/app/services/plazatype.service';
import {BsLocaleService} from "ngx-bootstrap/datepicker";
import {ModalDirective} from 'ngx-bootstrap/modal';
import {CheckboxControlValueAccessor, FormBuilder, FormGroup} from '@angular/forms';
import {Page} from "../../models/page";
import {HttpResponse} from "@angular/common/http";
import {Utilities} from "../../shared/helpers/util";
import {ClientTypeService} from "../../services/client-type.service";
import {ComissionTypeService} from "../../services/comission-type.service";
import {CurrencyService} from "../../services/currency.service";
import {createMask} from "@ngneat/input-mask";
import {MASK_AMOUNT} from "../../shared/constants/app.constants";

@Component({
  selector: 'app-reg-commissions',
  templateUrl: './reg-commissions.component.html',
  styleUrls: ['./reg-commissions.component.scss']
})
export class RegCommissionsComponent implements OnInit {

  @ViewChild('modalcommissions') limitforchannelModal: ModalDirective;
  @ViewChild('ValidaDeleteChannelModal') ValidaDeleteChannelModal: ModalDirective;
  amountInputMask = createMask(MASK_AMOUNT);
  page: Page;
  regcommissionsForm: FormGroup;
  deletedRow: any;
  rows = [] as any[];
  listPlazaType = [] as any[];
  listClientType = [] as any[];
  listCurrency = [] as any[];
  listComissionType = [] as any[];
  listBilateral = [] as any[];
  titleModal: string = "";
  loading: boolean = false;
  minDate: Date = new Date();

  constructor(
    private localeService: BsLocaleService,
    private commissionService: CommissionService,
    private plazatypeService: PlazatypeService,
    private clientTypeService: ClientTypeService,
    private comissionTypeService: ComissionTypeService,
    private currencyService: CurrencyService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit() {
    this.loading = true;

    this.page = {
      size: 10,
      pageNumber: 1,
      totalElements: 0,
      totalPages: 0
    };

    this.regcommissionsForm = this.formBuilder.group({
      id: [{value: 0, disabled: true}, []],
      genericBilateralFlag: [{value: -1, disabled: false}, []],
      feeCode: [{value: null, disabled: false}, []],
      participantCode: [{value: null, disabled: false}, []],
      commissionTypeCode: [{value: null, disabled: false}, []],
      currencyCode: [{value: null, disabled: false}, []],
      clientTypeCode: [{value: null, disabled: false}, []],
      plazaTypeCode: [{value: -1, disabled: false}, []],
      amountRangeFrom: [{value: null, disabled: false}, []],
      amountRangeTo: [{value: null, disabled: false}, []],
      percentage: [{value: null, disabled: false}, []],
      min: [{value: null, disabled: false}, []],
      max: [{value: null, disabled: false}, []],
      flatFee: [{value: null, disabled: false}, []],
      effectiveStartDate: [{value: null, disabled: false}, []],
      effectiveEndDate: [{value: null, disabled: false}, []]
    });

    this.titleModal = "Nuevo Comisiones";

    const filter = {} as any;
    filter.pageNumber = this.page.pageNumber;
    filter.pageSize = this.page.size;

    forkJoin({
      plazatypes: this.plazatypeService.GetALL(),
      comissions: this.commissionService.GetALL(filter),
      clientType: this.clientTypeService.GetALL(),
      comissionType: this.comissionTypeService.GetALL(),
      currency: this.currencyService.GetALL(),
      bilateralFlag:this.comissionTypeService.BilateralAll(),


    })
      .pipe(finalize(() => this.loading = false))
      .subscribe(req => {

        this.listCurrency = req.currency;
        this.listClientType = req.clientType;
        this.listComissionType = req.comissionType;
        this.listPlazaType = req.plazatypes;
        this.listBilateral = req.bilateralFlag;
        this.rows = [...req.comissions.rows];
        this.page.totalElements = req.comissions.totalRows;
        this.page.totalPages = req.comissions.maxPage;
      });
  }


  EditRecord(row: any = 0): void {
    this.titleModal = "Editar Comisiones";
    if (row.effectiveStartDate)
      row.effectiveStartDate = new Date(row.effectiveStartDate + 'Z');

    if (row.effectiveEndDate)
      row.effectiveEndDate = new Date(row.effectiveEndDate + 'Z');

    this.regcommissionsForm.patchValue(row);
    this.regcommissionsForm.get("genericBilateralFlag")?.disable();
    this.regcommissionsForm.get("feeCode")?.disable();
    this.regcommissionsForm.get("flatFee")?.disable();
    this.regcommissionsForm.get("amountRangeFrom")?.disable();
    this.regcommissionsForm.get("amountRangeTo")?.disable();
    this.regcommissionsForm.get("participantCode")?.disable();
    this.regcommissionsForm.get("commissionTypeCode")?.disable();
    this.regcommissionsForm.get("currencyCode")?.disable();
    this.regcommissionsForm.get("clientTypeCode")?.disable();
    this.regcommissionsForm.get("plazaTypeCode")?.disable();
    this.regcommissionsForm.get("effectiveStartDate")?.disable();
    this.regcommissionsForm.get("effectiveEndDate")?.disable();
    this.limitforchannelModal.show();
  }

  exportar(): void {
    this.loading = true;
    this.commissionService.getExportExcel()
      .pipe(finalize(() => this.loading = false))
      .subscribe((response: HttpResponse<Blob>) => {
        Utilities.downloadBlobFile(response.body as Blob, Utilities.getFileNameResponse(response));
      });
  }

  getData(): void {
    this.loading = true;
    const filter = {} as any;
    filter.pageNumber = this.page.pageNumber;
    filter.pageSize = this.page.size;
    this.commissionService.GetALL(filter)
      .pipe(finalize(() => this.loading = false))
      .subscribe(req => {
        this.rows = [...req.rows];
        this.page.totalElements = req.totalRows;
        this.page.totalPages = req.maxPage;
      });
  }

  setPage(pageInfo: any): void {
    this.page.pageNumber = pageInfo.offset + 1;
    this.getData();
  }

  cleanData(): void {
    this.regcommissionsForm.patchValue({
      id: 0,
      genericBilateralFlag: this.listBilateral[0]?.code ?? null,
      feeCode: null,
      participantCode:null,
      commissionTypeCode: this.listComissionType[0]?.code ?? null,
      currencyCode: this.listCurrency[0]?.id ?? null,
      clientTypeCode: this.listClientType[0]?.code ?? null,
      amountRangeTo:null,
      amountRangeFrom:null,
      flatFee:null,
      plazaTypeCode: -1,
      percentage: null,
      min: null,
      max: null,
      effectiveStartDate: null,
      effectiveEndDate: null
    });
  }

  OpenQuestionDeleteRecord(row: any = 0): void {
    this.deletedRow = row;
    this.ValidaDeleteChannelModal.show();
  }

  HideValidateDeleteChannel(): void {
    this.deletedRow = null;
    this.ValidaDeleteChannelModal.hide();
  }

  DeleteChannel(): void {
    this.commissionService.eliminar(this.deletedRow.id)
      .pipe(finalize(() => this.loading = false))
      .subscribe(x => {
        this.page.pageNumber = 1;
        this.getData();
        this.HideValidateDeleteChannel();
      })
  }

  Nuevo(): void {
    const data = this.regcommissionsForm.getRawValue();
    this.titleModal = "Nuevo Comisiones";
    this.cleanData();
    this.regcommissionsForm.enable();
    this.regcommissionsForm.get("id")?.disable();
    this.limitforchannelModal.show();


  }

  closeModal(): void {
    this.limitforchannelModal.hide();
  }

  SaveCommissions(): void {
    this.loading = true;
    const data = this.regcommissionsForm.getRawValue();
    if(data.genericBilateralFlag =='G'){
      data.participantCode == null;
    }
    if (data.id < 1) {

      this.commissionService.registrar(data)
        .pipe(finalize(() => this.loading = false))
        .subscribe(x => {
          this.page.pageNumber = 1;
          this.getData();
          this.closeModal();
        })

    } else {

      this.commissionService.modificar(data)
        .pipe(finalize(() => this.loading = false))
        .subscribe(x => {
          this.page.pageNumber = 1;
          this.getData();
          this.closeModal();
        })
    }
  }

}
