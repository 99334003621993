import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ComissionTypeService {

  constructor(private _http: HttpClient) {
  }

  private url: string = environment.url_base;

  GetALL(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}/ComissionType`);
  }

  BilateralAll(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}/ComissionType/admin/BilateralFlag`);
  }


}
