import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {Utilities} from "../shared/helpers/util";

@Injectable({
  providedIn: 'root'
})
export class ClientTypeService {

  constructor(private _http: HttpClient) {
  }

  private url: string = environment.url_base;

  GetALL(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}/ClientType`);
  }
}
