import { Component, OnInit } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConciliationService } from 'src/app/services/conciliation.service';
import { finalize } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { Utilities } from '../../shared/helpers/util';
import { Page } from '../../models/page';
import { ConciliationStatusService } from '../../services/conciliation-status.service';

@Component({
  selector: 'app-conciliation',
  templateUrl: './conciliation.component.html',
  styleUrls: ['./conciliation.component.scss'],
})
export class ConciliationComponent implements OnInit {
  page: Page;
  currentDate: Date = new Date();
  loading: boolean = false;
  isFechaLiquiFilter: boolean = true;

  bsValue = new Date();
  maxDate = new Date();
  minDate = new Date();

  soles: number = 0.0;
  dolares: number = 0.0;
  conciliationQueryForm: FormGroup = new FormGroup({});
  rows = [] as any[];
  status: any[] = [];
  constructor(
    private localeService: BsLocaleService,
    private conciliationService: ConciliationService,
    private conciliationStatusService: ConciliationStatusService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.localeService.use('es');

    this.page = {
      size: 10,
      pageNumber: 1,
      totalElements: 0,
      totalPages: 0,
      sort:'',
      sortType:''
    };

    this.conciliationQueryForm = this.formBuilder.group({
      liquidationDate: [{ value: new Date(), disabled: false }, []],
      startTransferDate: [{ value: null, disabled: false }, []],
      endTransferDate: [{ value: null, disabled: false }, []],
      state: [{ value: 2, disabled: false }, []],
      transferType: [{ value: -1, disabled: false }, []],
      typeCCI: [{ value: -1, disabled: false }, []],
      cCI: [{ value: null, disabled: false }, []],
      reference: [{ value: null, disabled: false }, []],
    });
    this.conciliationStatusService.GetALL().subscribe((x) => (this.status = x));
    //this.getConciliation();
  }

  setPage(pageInfo: any): void {
    this.page.pageNumber = pageInfo.offset + 1;
    this.getConciliation(pageInfo);
  }

  onChangeRadio1(e) {
    this.conciliationQueryForm.controls['startTransferDate'].setValue(null);
    this.conciliationQueryForm.controls['endTransferDate'].setValue(null);
    this.conciliationQueryForm.controls['liquidationDate'].setValue(new Date());
    this.isFechaLiquiFilter = true;
  }

  onChangeRadio2(e) {
    this.conciliationQueryForm.controls['liquidationDate'].setValue(null);
    this.conciliationQueryForm.controls['startTransferDate'].setValue(
      new Date()
    );
    this.conciliationQueryForm.controls['endTransferDate'].setValue(new Date());
    this.isFechaLiquiFilter = false;
  }

  getConciliation(pageInfo: any = null, sortInfo: any = null): void {
    this.loading = true;
    const filter = this.conciliationQueryForm.getRawValue();
    if (filter.state == -1) filter.state = null;
    if (filter.transferType == -1) filter.transferType = null;
    if (filter.typeCCI == -1) filter.typeCCI = null;
    else if (filter.typeCCI == 1) {
      filter.cCIOrigin = filter.cCI;
    } else if (filter.typeCCI == 2) {
      filter.cCIBeneficiary = filter.cCI;
    }

    filter.pageNumber = this.page.pageNumber;
    filter.pageSize = this.page.size;

    if (pageInfo == null) {
      filter.pageNumber = 1;
     this. page.pageNumber = 1;
    }

    if (sortInfo == null) {
      filter.order = this.page.sort;
      filter.orderType = this.page.sortType;
    } else {
      filter.order = sortInfo.prop;
      filter.orderType = sortInfo.dir;
    }
    this.conciliationService.getAll(filter).subscribe({
      next: (req) => {
        this.rows = [...req.rows];
        this.page.totalElements = req.totalRows;
        this.page.totalPages = req.maxPage;

        this.soles = 0.0;
        this.dolares = 0.0;

        if(this.rows.length > 0){

          this.soles = Number(this.rows[0].totalPen);
          this.dolares = Number(this.rows[0].totalUSD);
        }
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }

  Clear() {
    this.conciliationQueryForm.setValue({
      liquidationDate: null,
      startTransferDate: null,
      endTransferDate: null,
      state: 2,
      transferType: -1,
      typeCCI: -1,
      cCI: null,
      reference: null,
    });

    this.onChangeRadio1(null);
    this.getConciliation();
  }




  downloadExcel(): void {
    this.loading = true;
    const filter = this.conciliationQueryForm.getRawValue();
    if (filter.state == -1) filter.state = null;
    if (filter.transferType == -1) filter.transferType = null;
    if (filter.typeCCI == -1) filter.typeCCI = null;

    this.conciliationService
      .getExportContiliation(filter)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((response: HttpResponse<Blob>) => {
        Utilities.downloadBlobFile(
          response.body as Blob,
          Utilities.getFileNameResponse(response)
        );
      });
  }

  onSort(event) {
    const sort = event.sorts[0];
    this.page.sort = sort.prop;
    this.page.sortType = sort.dir;
    this.getConciliation(null, sort);
  }
}
