import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FraudparametersService {

  constructor(private _http: HttpClient) { }

  private url: string = environment.url_base;

  GetALL(): Observable<any> {
    return this._http.get(`${this.url}/Fraud`);
  }

  Modify(data:any): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(`${this.url}/Fraud`, params, { headers: headers });    
  }


}
