import { Component, OnInit } from '@angular/core';
import { UserInfo } from 'src/app/models/security';
import { AppConstants } from '../../constants/app.constants';

declare const hideMessage: any;
declare const showMessage: any;

@Component({
  selector: 'user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
  userInfo = {};//JSON.parse(sessionStorage.getItem(AppConstants.Session.USER_INFO)!);
  //TODO: revisar
  constructor() {
  }

  ngOnInit() {
  }

  showMessage(): void {
    showMessage();
  }

  hideMessage(): void {
    hideMessage();
  }

}
