import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FraudparametersService } from 'src/app/services/fraudparameters.service';
import { FraudParameter } from 'src/app/models/fraudparameter';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import {AppConstants, MASK_AMOUNT} from 'src/app/shared/constants/app.constants';
import {createMask} from "@ngneat/input-mask";

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.scss']
})
export class ParametersComponent {
  amountInputMask = createMask(MASK_AMOUNT);
  FraudQueryForm: FormGroup = new FormGroup({});
  loading: boolean = false;

  constructor(
    private fraudparametersService: FraudparametersService,
    private formBuilder: FormBuilder,
    private alertService: AlertService
  ){
  }

  ngOnInit(){
    this.FraudQueryForm = this.formBuilder.group({
      evaluaTime: [''],
      minamoutPen: [''],
      minamoutDoll: [''],
      transferSame: ['']
    });

    this.GetFraudParam();
  }

  GetFraudParam(): void{
    this.loading = true;
    this.fraudparametersService.GetALL().subscribe({
      next: (req) => {
        this.FraudQueryForm.controls['evaluaTime'].setValue(req.evaluationTime);
        this.FraudQueryForm.controls['minamoutPen'].setValue(req.minimumAmountPen);
        this.FraudQueryForm.controls['minamoutDoll'].setValue(req.minimumAmountDollars);
        this.FraudQueryForm.controls['transferSame'].setValue(req.transferSameClient);
        this.loading = false;
      }, error: (err) => {
        this.loading = false;
      }
    });
  }

  Save(): void{

    this.loading = true;
    var fraudUpd = new FraudParameter();
    fraudUpd.EvaluationTime = this.FraudQueryForm.value['evaluaTime'];
    fraudUpd.MinimumAmountPen = this.FraudQueryForm.value['minamoutPen'];
    fraudUpd.MinimumAmountDollars = this.FraudQueryForm.value['minamoutDoll'];
    fraudUpd.TransferSameClient = this.FraudQueryForm.value['transferSame'];

      this.fraudparametersService.Modify(fraudUpd).subscribe({
        next: (req) => {
          this.GetFraudParam();
          this.loading = false;
          this.alertService.showMessage(AppConstants.TitleModal.UPDATE_PARAMETERS_FRAUD, AppConstants.Messages.SUCCESS_DATA, MessageSeverity.success);
        }, error: (err) => {
          this.loading = false;
        }
      });


  }

  Cancel(): void{
    this.GetFraudParam();
  }

}
