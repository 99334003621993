import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {AppConstants} from '../constants/app.constants'
import {Router} from '@angular/router';
import {LoginService} from '../../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private authService: LoginService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tokken = sessionStorage.getItem(AppConstants.Session.ACCESS_TOKEN);

    let request = req;

    if (tokken && !req.url.includes("Security/token")) {
      let tokenReplace = tokken.replace(/['"]+/g, '')
      request = req.clone({
        setHeaders: {
          Authorization: `Bearer ${tokenReplace}`
        }
      });
    }
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.authService.logout();
          this.router.navigateByUrl('/login');
        }
        return throwError(err);
      })
    );

  }

}
