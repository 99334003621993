export class UserInfo {
  username: string = "";
  agency: string = "";
  bankCode: string = "";
  channel: string = "";
  userType: string = "";
  userTypeId: number = 0;
  company: string = "";
  companyId: number = 0;
  profileId: number = 0;
  profile: string = "";
  menu: any;
};


export class TokenModel {
  access_token: string;
  expires_in: number;
  token_type: string;
}

export abstract class BaseModel {

  constructor() {
  }

  public setAll(_params: any) {
    for (const param in _params) {
      if (_params.hasOwnProperty(param)) {
        this[param] = _params[param];
      }
    }
  }
}

export class UserPasswordModel extends BaseModel {
  userId: string;
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}