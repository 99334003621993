import { InputmaskOptions } from "@ngneat/input-mask";

export const AppConstants = {
  System: 1,
  Session: {
    ACCESS_TOKEN: 'ACCESS_TOKEN',
    USERID: 'USERID',
    USERNAME: 'USERNAME',
    TIMESECONDS: 'TIMESECONDS',
    CHANGEPASSWORD: 'CHANGEPASSWORD',
    EXPIREDPASSWORD: 'EXPIREDPASSWORD',
    USERROLID: 'ROLID',
    USERROLNAME: 'ROLNAME',
    EMAIL: 'CORREO',
    ACCESS_TOKEN_RIPLEY: 'ACCESS_TOKEN_RIPLEY',
    AGENCYCODE: 'AGENCY_CODE',
    AGENCYNAME: 'AGENCY_NAME',
    SALESCHANNEL: 'SALES_CHANNEL',
    SALES_CHANNEL_DESCRIPTION: 'SALES_CHANNEL_DESCRIPTION',
    FLAG_CANCEL_SOLICITUD: 'FLAG_CANCEL_SOLICITUD',
    MENU: 'MENU'
  },
  TitleModal: {
    UPDATE_PARAMETERS_FRAUD: 'Editar Gestión de fraude',
    EXPORT_CONCILIATION: 'Exportar conciliacion',
    ADD_PARAMETERS_CHANNEL: 'Agregar canal',
    UPDATE_PARAMETERS_CHANNEL: 'Editar canal',
    DELETE_PARAMETERS_CHANNEL: 'Eliminar canal',

    EXPORT_PARAMETERS_LIMITCHANNEL: 'Exportar límites por canal',
    ADD_PARAMETERS_LIMITCHANNEL: 'Agregar límites por canal',
    UPDATE_PARAMETERS_LIMITCHANNEL: 'Editar  límites por canal',
    DELETE_PARAMETERS_LIMITCHANNEL: 'Eliminar  límites por canal',

    EXPORT_PARAMETERS_COMMISSIONS: 'Exportar comisiones',
    ADD_PARAMETERS_COMMISSIONS: 'Agregar comisiones',
    UPDATE_PARAMETERS_COMMISSIONS: 'Editar comisiones',
    DELETE_PARAMETERS_COMMISSIONS: 'Eliminar comisiones',

    APROB_MANAGMENT_FRAUD: 'Autorizar transferencia',
    DENE_MANAGMENT_FRAUD: 'Denegar transferencia',

    EXPORTAR_OFICINAS: 'Exportar oficinas',
    IMPORTAR_OFICINAS: 'Importar oficinas',

    EXPORT_CONSULT_TRANSFER: 'Exportar transferencia de entrada',
    EXPORT_CONSULT_TRANSFER_OUT: 'Exportar transferencia de salida',

    GET_PARAMETER: 'Consulta Parametro',
    ADD_PARAMETER: 'Agregar Parametro',
    UPDATE_PARAMETER: 'Editar Parametro',
    GET_APPLICATION: 'Consulta Aplicación',
    ADD_APPLICATION: 'Agregar Aplicación',
    UPDATE_APPLICATION: 'Editar Aplicación',
    GET_MENU: 'Consulta Menú',
    ADD_MENU: 'Agregar Menú',
    UPDATE_MENU: 'Editar Menú',
    GET_USER: 'Consulta Usuario',
    ADD_USER: 'Agregar Usuario',
    UPDATE_USER: 'Editar Usuario',
    GET_COMPANY: 'Consulta Empresa',
    ADD_COMPANY: 'Agregar Empresa',
    UPDATE_COMPANY: 'Editar Empresa',
    GET_PROFILE: 'Consulta Perfil',
    ADD_PROFILE: 'Agregar Perfil',
    UPDATE_PROFILE: 'Editar Perfil',
    GET_ACTION: 'Consulta Acción',
    ADD_ACTION: 'Agregar Acción',
    UPDATE_ACTION: 'Editar Acción',
    LOGIN_TITLE: 'Inicio Sesión',
    RESET_PASSWORD: 'Restablecer Contraseña',
    VALIDATE_USER: 'Validar usuario en AD',
    CHANGE_STATE: 'Cambio de estado',
    CHANGE_PASSWORD: 'Cambiar contraseña.',
    REQUIRED_CHANGE_PASSWORD: 'Es necesario que cambie su contraseña.',
    EXPIRED_PASSWORD: 'Su contraseña ya expiró, es necesario que la cambie.',
   },
  Actions: {
    GET: 'Consultar',
    ADD: 'Agregar',
    UPDATE: 'Editar',
  },
  ParametryCode: {
    COUNTRY: 1,
    ACCESS_TYPE: 2,
    DOCUMENT_TYPE: 3,
    COMPANY_TYPE: 4,
    USER_TYPE: 5,
  },
  LoginErrorMessages: {
    usuario: {
        required: 'Ingrese su usuario.'
    },
    contrasena: {
        required: 'Ingrese su contraseña.'
    },

    currentPassword: {
        required: 'Ingrese su contraseña actual.',
        minlength: 'Ingrese una contraseña minima de 8 caracteres.',
        pattern: 'La contraseña debe contener mayusculas, minusculas, números y caracteres especiales.'
    },
    newPassword: {
        required: 'Ingrese su nueva contraseña.',
        minlength: 'Ingrese una contraseña minima de 8 caracteres.',
        pattern: 'La contraseña debe contener mayusculas, minusculas, números y caracteres especiales.'
    },
    confirmNewPassword: {
        required: 'Confirme su nueva contraseña.',
        minlength: 'Ingrese una contraseña minima de 8 caracteres.',
        pattern: 'La contraseña debe contener mayusculas, minusculas, números y caracteres especiales.'
    }
},

  Messages: {
    SUCCESS_PASSWORD: 'Contraseña modificada',
    SUCCESS_DATA: 'Datos modificados',
    SUCCESS_VALIDATE: 'Datos validados',
    PASSWORDS_INCORRECTS: 'Las constraseñas no son iguales.',
    DIFFERENT_PASSWORD: 'Las contraseñas deben coincidir',
    CHANGE_PASSWORD_CORRECT: 'La contraseña se actualizó con exito.',
    CHANGED_PASSWORD: 'Contraseña modificada',
    PARAMETER_EMPTY: 'Debe llenar los campos',
    MENU_EMPTY: 'Debe seleccionar una aplicación',
    MENU_ITEM: 'Debe seleccionar al menos un item',
    NO_DATA: 'No hay datos',
    NOT_ALLOWED: 'Acción no permitida',
    ERROR_OCURRED: 'Ocurrio un error',
    DIFFERENT_ROL: 'No puede agregar dos roles a una misma aplicación',
    ACTIVE_DIRECTORY: 'Necesita validar en Active Directory',
    LOGIN_ERROR: 'Usuario Sin Autorización',
    SUCCESS_REGISTER: 'Registrado correctamente',
    SUCCESS_EDIT: "Modificado correctamente",
    RECAPTCHA_ERROR: 'Error en validación reCaptcha',
    EMAIL_INVALID: 'El Email tiene un formato invalido',
    DOCUMENT_NUMBER_INVALID: 'El Numero de documento ingresado tiene un formato invalido',
    USER_DOESNT_EXIST: 'El usuario o la contraseña son incorrectos.',
    PASSWORD_DOESNT_CORRECT: 'El usuario o la contraseña son incorrectos.',
    USER_IS_INACTIVE: 'El usuario o la contraseña son incorrectos.',
    USER_IS_LOCKED: 'Usuario bloqueado.',

  },
  MenuActions: {
    GET_PARAMETER: 1,
    ADD_PARAMETER: 2,
    UPDATE_PARAMETER: 3,
    GET_DETAIL_PARAMETER: 4,
    ADD_DETAIL_PARAMETER: 5,
    UPDATE_DETAIL_PARAMETER: 6,
    GET_APPLICATION: 7,
    ADD_APPLICATION: 8,
    UPDATE_APPLICATION: 9,
    GET_MENU: 30,
    ADD_MENU: 10,
    UPDATE_MENU: 11,
    GET_PROFILE: 12,
    ADD_PROFILE: 13,
    UPDATE_PROFILE: 14,
    GET_ACTION: 34,
    ADD_ACTION: 35,
    GET_USER: 15,
    ADD_USER: 16,
    UPDATE_USER: 17,
    RESET_PASSWORD: 18,
    GET_COMPANY: 31,
    ADD_COMPANY: 32,
    UPDATE_COMPANY: 33,
    ACTIVATE_USER: 36,
    DEACTIVATE_USER: 37,
    VIEW_DETAIL_USER: 38
  },
  UserType: {
    Internal: 1,
    External: 2
  },
  Application: {
    Security: 1
  },

  TypeScore: {
    OPERATION: 'Operacion',
    CLIENT: 'Cliente'
  }

}

export const MASK_AMOUNT: InputmaskOptions<number> = {
  alias: 'numeric',
  groupSeparator: ',',
  digits: 2,
  digitsOptional: false,
  placeholder: '0',
  parser: (value: string) => Number(value.replace(/[^\d.-]/g, '')),
};
export const LoginMessages = {
  ACTIVIDAD_SOSPECHOSA: 'Error al indentificarse. Se detectó actividad sospechosa.'
};