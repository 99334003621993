import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {SharedModule} from './shared/module/shared.module';

import {ToastaModule} from 'ngx-toasta';
import {ModalModule} from 'ngx-bootstrap/modal';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {environment} from '../environments/environment';
import {ServiceWorkerModule} from '@angular/service-worker';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {LoginComponent} from './components/login/login.component';
import {MenuComponent} from './components/menu/menu.component';
import {HomeComponent} from './components/home/home.component';
import {LoadingComponent} from './shared/components/loading/loading.component';
import {HeaderComponent} from './shared/components/header/header.component';
import {LayoutComponent} from './shared/components/layout/layout.component';
import {UserInfoComponent} from './shared/components/user-info/user-info.component';
import {ConciliationComponent} from './components/conciliation/conciliation.component';
import {ParametersComponent} from './components/parameters/parameters.component';
import {RegChannelsComponent} from './components/reg-channels/reg-channels.component';
import {RegLimitChannelsComponent} from './components/reg-limit-channels/reg-limit-channels.component';
import {RegCommissionsComponent} from './components/reg-commissions/reg-commissions.component';
import {RegOfficesComponent} from './components/reg-offices/reg-offices.component';
import {ManagmentFraudComponent} from './components/managment-fraud/managment-fraud.component';
import {ConsultTransferComponent} from './components/consult-transfer/consult-transfer.component';
import {ConsultTransferOutComponent} from './components/consult-transfer-out/consult-transfer-out.component';
import {InputMaskModule} from "@ngneat/input-mask";
import { AuthInterceptor } from './shared/services/auth-interceptor.service';
import { ErrorsInterceptor } from './shared/services/errors.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MenuComponent,
    HomeComponent,
    LoadingComponent,
    HeaderComponent,
    LayoutComponent,
    UserInfoComponent,
    ConciliationComponent, ParametersComponent, RegChannelsComponent, RegLimitChannelsComponent, RegCommissionsComponent, RegOfficesComponent, ManagmentFraudComponent, ConsultTransferComponent, ConsultTransferOutComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    BrowserModule,
    HttpClientModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    ToastaModule.forRoot(),
    AppRoutingModule,
    InputMaskModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaKey}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
