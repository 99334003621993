<header *ngIf="!bandejaActive"
  [className]="isNavRipley ? 'header-master nav-active d-none d-md-block nav-navegation-scroll' : 'header-master d-none d-md-block nav-navegation-scroll' ">
  <div class="opacity" (click)="hideNav()"></div>
  <div class="left-head">
    <button type="button" class="btn-show-nav" (click)="showNav()" *ngIf="showTransaction();"></button>
    <div class="logotipo-system">
      <img src="/assets/images/Ripley Logo 2.png" />
    </div>
    <div class="nav-custom-admin nav-item-admin" *ngIf="!showTransaction();">

      <a *ngFor="let menu of arrayMenu" (click)="goToRoute(menu.menuUrl)">
         <div class="img-icon-menu-admin"><img src="{{menu.menuImage}}" /></div>
        <div class="text-menu-admin text-center">{{menu.menuName}}</div>
      </a>
      <!-- <a  (click)="goToRoute('home')">
        <div class="img-icon-menu-admin"><img src="/assets/images/HomeIcon.png" /></div>
       <div class="text-menu-admin text-center">Inicio</div>
      </a>

      <a  (click)="goToRoute('conciliation')">
        <div class="img-icon-menu-admin"><img src="/assets/images/HomeIcon.png" /></div>
       <div class="text-menu-admin text-center">Conciliacion</div>
      </a>      

      <a  (click)="goToRoute('parameters')">
        <div class="img-icon-menu-admin"><img src="/assets/images/HomeIcon.png" /></div>
       <div class="text-menu-admin text-center">Parámetros - Gestión de Fraude</div>
      </a>    

      <a  (click)="goToRoute('reg-channels')">
        <div class="img-icon-menu-admin"><img src="/assets/images/HomeIcon.png" /></div>
       <div class="text-menu-admin text-center">Parámetros - Canal        
       </div>
      </a>    

      <a  (click)="goToRoute('reg-limit-channels')">
        <div class="img-icon-menu-admin"><img src="/assets/images/HomeIcon.png" /></div>
       <div class="text-menu-admin text-center">Parámetros - Limites por canal</div>
      </a> 

      <a  (click)="goToRoute('reg-commissions')">
        <div class="img-icon-menu-admin"><img src="/assets/images/HomeIcon.png" /></div>
       <div class="text-menu-admin text-center">Parámetros - Comisiones</div>
      </a> 

      <a  (click)="goToRoute('reg-offices')">
        <div class="img-icon-menu-admin"><img src="/assets/images/HomeIcon.png" /></div>
       <div class="text-menu-admin text-center">Maestro de oficinas</div>
      </a> 

      <a  (click)="goToRoute('managment-fraud')">
        <div class="img-icon-menu-admin"><img src="/assets/images/HomeIcon.png" /></div>
       <div class="text-menu-admin text-center">Gestión de Transferencia</div>
      </a> 

      <a  (click)="goToRoute('consult-transfer')">
        <div class="img-icon-menu-admin"><img src="/assets/images/HomeIcon.png" /></div>
       <div class="text-menu-admin text-center">Transferencia - Entrada</div>
      </a> 

      <a  (click)="goToRoute('consult-transfer-out')">
        <div class="img-icon-menu-admin"><img src="/assets/images/HomeIcon.png" /></div>
       <div class="text-menu-admin text-center">Transferencia - Salida</div>
      </a> 


      <a  (click)="goToRoute('salir')">
        <div class="img-icon-menu-admin"><img src="/assets/images/ExitIcon.png" /></div>
        <div class="text-menu-admin text-center">Salir</div>
      </a> -->



    </div>
  </div>
</header>
<header *ngIf="!bandejaActive" class="header-mobile d-block d-md-none"
  [className]="isNavRipley ? 'header-mobile nav-active d-block d-md-none' : 'header-mobile d-block d-md-none' ">
  <button type="button" class="btn-show-nav-mobile" (click)="showNav()"></button>
  <div class="opacity" (click)="hideNav()"></div>
  <img class="img-header-mobile" src="../../../../assets/images/mobile/simulator_header.png">
  <nav class='nav-navegation nav-navegation-scroll'>
    <button type="button" class="btn-show-nav" (click)="showNav()" *ngIf="showTransaction();"></button>
    <div class="logotipo-system">
      <img src="/assets/images/Ripley Logo 2.png" />
    </div>
    <div class="nav-custom-admin nav-item-admin" *ngIf="!showTransaction();">
      <a *ngFor="let menu of arrayMenu" (click)="goToRoute(menu.componentView)">
        <div class="img-icon-menu-admin"><img src="{{menu.icon}}"/></div>
        <div class="text-menu-admin">{{menu.name}}</div>
      </a>
      <a  (click)="goToRoute('salir')">
        <div class="img-icon-menu-admin"><img src="/assets/images/ExitIcon.png" /></div>
        <div class="text-menu-admin text-center">Salir</div>
      </a>
    </div>
  </nav>
</header>

<div class="modal fade" bsModal [config]="{ backdrop: 'static', keyboard: false,animated:false}"
  #prototipoModal="bs-modal" tabindex="-1">
  <div class="modal-dialog modal-sm" style="top: 211px;">
    <div class="modal-content modal-logout">
      <div class="modal-body text-center">
        <div class="title-logout-modal">¿Está seguro de salir del sistema?</div>
        <div class="row" style="padding-bottom: 22px;">
          <div class="offset-1 col-5">
            <button class="button-logout pull-right" (click)="cancelLogout()">Cancelar</button>
          </div>
          <div class="col-5">
            <button class="button-logout pull-left" (click)="confirmLogout()">Aceptar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" bsModal [config]="{backdrop: 'static', keyboard: false}" #passwordModal="bs-modal"
  tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">{{modalTitle}}</h5>
      </div>
      <form [formGroup]="resetPasswordForm">
        <div class="modal-body">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="currentPassword">Contraseña actual:</label>
              <input class="form-control" id="currentPassword" formControlName="currentPassword" type="password" />
              <div class="form-control-feedback required" *ngIf="resetPasswordForm.get('currentPassword')?.errors">
                {{formErrors['currentPassword']}}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-12">
              <label for="newPassword">Nueva contraseña:</label>
              <input class="form-control" id="newPassword" formControlName="newPassword" type="password" />
              <div class="form-control-feedback required"
                *ngIf="resetPasswordForm.get('newPassword')?.errors || equalsPassword">
                {{formErrors['newPassword']}}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-12">
              <label for="confirmNewPassword">Repetir nueva contraseña:</label>
              <input class="form-control" id="confirmNewPassword" formControlName="confirmNewPassword"
                type="password" />
              <div class="form-control-feedback required"
                *ngIf="resetPasswordForm.get('confirmNewPassword')?.errors || equalsPassword">
                {{formErrors['confirmNewPassword']}}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="text-align:center;">
          <div class="row justify-content-center w-100">
            <div class="col-6 col-md-4">
              <button type="button" class="button-general" style="margin:auto;display:block;" (click)="changePassword()"
                title="Guardar">Guardar</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<app-loading *ngIf="loading"></app-loading>
