import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { ChannelService } from 'src/app/services/channel.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChannelsQuery } from 'src/app/models/channelsquery';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ChannelNew } from 'src/app/models/channelnew';
import { ThisReceiver } from '@angular/compiler';
import { threeDotsVertical } from 'ngx-bootstrap-icons';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { AppConstants } from 'src/app/shared/constants/app.constants';
import {finalize} from "rxjs";
import {HttpResponse} from "@angular/common/http";
import {Utilities} from "../../shared/helpers/util";

@Component({
  selector: 'app-reg-channels',
  templateUrl: './reg-channels.component.html',
  styleUrls: ['./reg-channels.component.scss']
})
export class RegChannelsComponent implements OnInit{

  @ViewChild('actionTemplate') actionTemplate: TemplateRef<any>;
  @ViewChild('channelModal') channelModal: ModalDirective;
  @ViewChild('ValidaDeleteChannelModal') ValidaDeleteChannelModal: ModalDirective;
  loading: boolean = false;
  rows = [] as any[];
  columns = [] as any[];
  rowDelete: any = 0;
  rowUpdate: any = 0;
  titleModal: string = "";
  mode: string = "";
  channelForm: FormGroup = new FormGroup({});
  channelQueryForm: FormGroup = new FormGroup({});

  constructor(
    private channelService: ChannelService,
    private formBuilder: FormBuilder,
    private alertService: AlertService
  ){

  }

  ngOnInit(){

    this.channelForm = this.formBuilder.group({
      code: [''],
      name: ['']
    });

    this.channelQueryForm = this.formBuilder.group({
      descp: [''],
      mnemo: ['']
    });

    setTimeout(() => {
      this.columns = [
        { prop: 'idChannel', name:'ID',width: 100 },
        { prop: 'code', name:'Mnemónico' },
        { prop: 'name', name:'Nombre' },
        { cellTemplate: this.actionTemplate, name: '', width: 100, sortable: false, draggable: false, resizeable: false, cellClass: "text-center" },
      ];

    }, 0);

    var modelRequest = new ChannelsQuery();
    modelRequest.Code = "";
    modelRequest.Name = "";
    this.GetChannels(modelRequest);
  }

  GetChannels(model: ChannelsQuery): void{

    this.loading = true;
    this.channelService.FilterAll(model).subscribe({
      next: (req) => {
        this.rows = req;
        this.loading = false;
      }, error: (err) => {
        this.loading = false;
      }
    });
  }


  OpenModal(row: any = 0): void {
    this.titleModal = "Editar Canal";
    this.mode = "EDIT";
    this.rowUpdate = row;
    this.channelForm.controls['code'].setValue(this.rowUpdate.code);
    this.channelForm.controls['name'].setValue(this.rowUpdate.name);
    this.channelForm.controls['code'].disable();

    this.channelModal.show();
  }

  NewChannelModal(): void {
    this.titleModal = "Nuevo Canal";
    this.mode = "NEW";
    this.channelForm.controls['code'].enable();
    this.channelModal.show();
  }

  closeModal(): void {
    if(this.mode == "EDIT"){
      this.channelForm.controls['code'].enable();
    }
    this.LimpiarPopData();
    this.channelModal.hide();
  }

  OpenQuestionDeleteChannel(row: any = 0): void{
    this.rowDelete = row;
    this.ValidaDeleteChannelModal.show();
  }

  HideValidateDeleteChannel(): void{
    this.ValidaDeleteChannelModal.hide();
  }

  SaveChannel(): void {
    var modelRequest = new ChannelsQuery();
    modelRequest.Code = "";
    modelRequest.Name = "";
    if(this.mode == "NEW"){
      this.loading = true;
      const channelNew =this.channelForm.getRawValue();
      this.channelService.Register(channelNew).subscribe({
        next: (req) => {
          this.GetChannels(modelRequest);
          this.channelModal.hide();
          this.LimpiarPopData();
          this.alertService.showMessage(AppConstants.TitleModal.ADD_PARAMETERS_CHANNEL, AppConstants.Messages.SUCCESS_DATA, MessageSeverity.success);
          this.loading = false;
        }, error: (err) => {
          this.alertService.showMessage(AppConstants.TitleModal.ADD_PARAMETERS_CHANNEL, AppConstants.Messages.NO_DATA, MessageSeverity.error);
          this.loading = false;
        }
      });


    }
    else{
      this.loading = true;
      const channelNew = this.channelForm.getRawValue();
      this.channelService.Modify(channelNew).subscribe({
        next: (req) => {
          this.GetChannels(modelRequest);
          this.channelModal.hide();
          this.LimpiarPopData();
          this.alertService.showMessage(AppConstants.TitleModal.UPDATE_PARAMETERS_CHANNEL, AppConstants.Messages.SUCCESS_DATA, MessageSeverity.success);
          this.loading = false;
        }, error: (err) => {
          this.alertService.showMessage(AppConstants.TitleModal.UPDATE_PARAMETERS_CHANNEL, AppConstants.Messages.NO_DATA, MessageSeverity.error);
          this.loading = false;
        }
      });
    }
  }


  Filtrar(){
    var modelRequest = new ChannelsQuery();
    modelRequest.Code = this.channelQueryForm.value['mnemo'];
    modelRequest.Name = this.channelQueryForm.value['descp'];
    this.GetChannels(modelRequest);
  }

  Limpiar(){
    this.channelQueryForm.controls['descp'].setValue("");
    this.channelQueryForm.controls['mnemo'].setValue("");
  }

  LimpiarPopData(){
    this.channelForm.controls['code'].setValue("");
    this.channelForm.controls['name'].setValue("");
  }
  exportExcel(): void {
    this.loading = true;
    this.channelService.getExportExcel()
      .pipe(finalize(() => this.loading = false))
      .subscribe((response: HttpResponse<Blob>) => {
        Utilities.downloadBlobFile(response.body as Blob, Utilities.getFileNameResponse(response));
      });
  }
  DeleteChannel(): void {
    this.loading = true;
    var modelRequest = new ChannelsQuery();
    modelRequest.Code = "";
    modelRequest.Name = "";
    this.channelService.Delete(this.rowDelete.code).subscribe({
      next: (req) => {
        this.GetChannels(modelRequest);
        this.ValidaDeleteChannelModal.hide();
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_PARAMETERS_CHANNEL, AppConstants.Messages.SUCCESS_DATA, MessageSeverity.success);
        this.loading = false;
      }, error: (err) => {
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_PARAMETERS_CHANNEL, "No se pudo eliminar el canal.", MessageSeverity.error);
        this.loading = false;
        console.log("error Delete channel", err);
      }
    });
  }

}
