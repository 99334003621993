<div class="wrap-view">
  <div class="row m-0" style="margin-top:-3%;">
    <div class="col-12 d-none d-md-block">
      <div class="row">
        <div class="col-md-4" style="padding:0">
          <img src="/assets/images/Chispas 1.png" />
        </div>
        <div class="col-md-4" style="height:20px;"></div>
        <div class="col-md-4">
          <img src="/assets/images/Chispas 2.png" />
        </div>
      </div>
    </div>
  </div>
  <div class="company-title">
    <div class="title-maestro">
      <span>Párametros - Límites por canal</span>
    </div>
    <div class="box-white container-fluid m-1" style="padding: 20px;">
      <label>
        <strong>Resultado</strong>
      </label>
      <div class="row m-1">
        <div class="col-12" style="text-align: end">
          <!--          <button class="form-control" style="width: 50px;margin-right: 10px;"-->
          <!--                  type="button" class="button-general" title="Buscar" (click)="getData()">-->
          <!--            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"-->
          <!--                 fill="currentColor" class="bi bi-search"-->
          <!--                 viewBox="0 0 16 16">-->
          <!--              <path-->
          <!--                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>-->
          <!--            </svg>-->
          <!--          </button>-->
          <button class="form-control" type="button" class="button-general" style="width: 50px;margin-right: 10px;"
            title="Exportar" (click)="exportExcel()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download"
              viewBox="0 0 16 16">
              <path
                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
              <path
                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
          </button>
          <button class="form-control" type="button" class="button-general" style="width: 50px;" title="Nuevo"
            (click)="NewReg()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-file-earmark-plus-fill" viewBox="0 0 16 16">
              <path
                d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM8.5 7v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 1 0z" />
            </svg>
          </button>
        </div>
      </div>
      <div class="grid-control-custom">
        <div style="overflow-y: auto; max-height: 450px!important;">
          <ngx-datatable class="material colored-header sm table table-hover" [rows]="rows" columnMode="force"
            [footerHeight]="40" [headerHeight]="60" [rowHeight]="30" [scrollbarH]="true" #table [externalPaging]="true"
            [count]="page.totalElements??0" [offset]="page.pageNumber??0" [limit]="page.size" (page)="setPage($event)"
            [messages]="{ emptyMessage: 'No records found', totalMessage: 'total' }">
            <ngx-datatable-column name="Id" [width]="50" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.id}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Principal" [width]="80" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.principal?'SI':'NO'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Tipo Transferencia" [width]="200" [sortable]="false" [resizeable]="false"
              [cellClass]="'text-left'">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.operationTypeCode}} - {{row.operationTypeName}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Descripción" [sortable]="false" [resizeable]="false" [cellClass]="'text-left'">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.description}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Canal" [width]="100" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.channelCode}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Alcance" [width]="100" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.scope == 1 ? 'Operación' : 'Cliente'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Limite S/" [width]="100" [sortable]="false" [resizeable]="false"
              [cellClass]="'text-right'">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.limitPen|number:'1.2-2'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Limite $" [width]="100" [sortable]="false" [resizeable]="false"
              [cellClass]="'text-right'">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.limitDollar|number:'1.2-2'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="100" name="F.I.V" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.effectiveStartDate|date:'dd/MM/yyyy'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="100" name="F.F.V" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{row.effectiveEndDate|date:'dd/MM/yyyy'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="100" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <!-- <button class="btn btn-xs p-0" (click)="EditRecord(row)">
                  <i class="fa fa-edit" aria-hidden="true" style="color:#B25BC2;" title="Editar"></i>
                </button> -->

                <button class="btn btn-xs p-0" style="margin-left: 10px" (click)="OpenQuestionDeleteRecord(row)">
                  <i class="fa fa-trash-o" aria-hidden="true" style="color:#B25BC2;" title="Eliminar">
                  </i>
                </button>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
                <div class="page-count">{{ rowCount.toLocaleString() }} elements</div>
                <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'" [page]="page.pageNumber??0" [size]="page.size??0"
                  [count]="page.totalElements??0" class="datatable-pager" (change)="table.onFooterPage($event)">
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loading *ngIf="loading"></app-loading>
<div class="modal fade" bsModal [config]="{ backdrop: 'static', keyboard: false,animated:false}"
  #ValidaDeleteChannelModal="bs-modal" tabindex="-1">
  <div class="modal-dialog modal-sm" style="top: 211px;">
    <div class="modal-content modal-logout">
      <div class="modal-body text-center">
        <div class="title-logout-modal">¿Está seguro de Eliminar el Registro?</div>
        <div class="row" style="padding-bottom: 22px;">
          <div class="offset-1 col-5">
            <button class="button-logout pull-right" (click)="HideValidateDeleteChannel()">Cancelar</button>
          </div>
          <div class="col-5">
            <button class="button-logout pull-left" (click)="DeleteChannel()">Aceptar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" bsModal [config]="{ backdrop: 'static', keyboard: false,animated:false}"
  #limitforchannelModal="bs-modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content modal-company-add">
      <div class="modal-header">
        <h5 class="modal-title pull-left">{{titleModal}}</h5>
      </div>
      <div class="modal-body text-left">
        <form class="form-cliente" autocomplete="offs" [formGroup]="limitchannelsForm">
          <div class="row">
            <div class="form-group col-6">
              <label class="subtitle-modal">id</label>
              <input class="form-control" type="text" formControlName="id" />
            </div>
            <div class="form-group col-6">
              <label class="subtitle-modal"> </label>
              <div class="">
                <label class="form-check-label" for="principal">
                  Principal
                </label>
                <input type="checkbox" formControlName="principal" id="principal" style="margin-left: 15px"
                  (change)="onChangePrincipal()" />
              </div>
            </div>
            <div class="form-group col-6">
              <label class="subtitle-modal">Tipo transferencia</label>
              <select class="form-select form-select-sm" aria-label=".form-select-sm example"
                formControlName="operationTypeCode">
                <option [value]="-1">Seleccione</option>
                <option *ngFor="let item of listOperationType" [value]="item.code">{{item.code + " - " + item.name}}
                </option>
              </select>
            </div>
            <div class="form-group col-6">
              <label class="subtitle-modal">Canal</label>
              <select class="form-select form-select-sm" formControlName="channelCode">
                <option [value]="-1">Seleccionar</option>
                <option *ngFor="let item of listChannel" [value]="item.code">{{item.code + " - " + item.name}}</option>
              </select>
            </div>
            <div class="form-group col-6">
              <label class="subtitle-modal">Límites</label>
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text">S/</span>
                </div>
                <input type="text" class="form-control" [inputMask]="amountInputMask" formControlName="limitPen">
              </div>
            </div>
            <div class="form-group col-6">
              <label class="subtitle-modal"> </label>
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input type="text" class="form-control" [inputMask]="amountInputMask" formControlName="limitDollar">
              </div>
            </div>
            <div class="form-group col-sm-6">
              <label class="subtitle-modal">Vigencia</label>
              <input type="text" autocomplete="off" bsDatepicker formControlName="effectiveStartDate"
              [minDate]="minDate"
              [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
              class="form-control" id="startVigency" />
            </div>
            <div class="form-group col-sm-6">
              <label class="subtitle-modal">Hasta</label>
              <input type="text" autocomplete="off"
              [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
               bsDatepicker formControlName="effectiveEndDate" class="form-control"
                id="endVigency" />
            </div>
            <div class="form-group ">
              <label class="subtitle-modal">Descripción</label>
              <textarea class="form-control" type="text" rows="3" formControlName="description"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-6">
              <label class="subtitle-modal">Alcance</label>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="idAlcance" id="idAlcance" checked>
                <label style="margin-left: 10px;">Operación</label>
              </div>
              <div class="form-check ">
                <input class="form-check-input" type="radio" name="idAlcance" id="idAlcance" disabled>
                <label style="margin-left: 10px;">Cliente</label>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer justify-content-evenly">
        <button type="button" class="button-text d-none d-md-block" style="width: 180px !important;" title="Cancelar"
          (click)="closeModal()">Cancelar
        </button>
        <button class="button-general d-none d-md-block" style="width: 180px !important;" title="Confirmar"
          (click)="SaveLimiForchannel()">Confirmar
        </button>
        <div class="col-6 m-0 ps-2 d-block d-md-none">
          <button type="button" class="button-text w-100" title="Cancelar">Cancelar
          </button>
        </div>
      </div>


    </div>
  </div>
</div>