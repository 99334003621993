import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AlertService, MessageSeverity} from './alert.service';
import {type} from "os";
import {AppConstants} from "../constants/app.constants";

@Injectable({
  providedIn: 'root'
})
export class ErrorsInterceptor implements HttpInterceptor {

  constructor(private alertService: AlertService) {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(async err => {
        if(err) {
          debugger
          if (typeof err.error?.error === 'string' || err.error?.error instanceof String) {
            if(err.error?.error === 'unauthorized_client'){
              this.alertService.showMessage('', AppConstants.Messages.LOGIN_ERROR, MessageSeverity.error);
            }
          }else{
            if (err.error?.errors && err.error?.errors?.messages && err.error?.errors?.messages.length > 0) {
              for (const message of err.error?.errors?.messages) {
                this.alertService.showMessage('', message, MessageSeverity.error);
              }
            } else {
              this.alertService.showMessage('', 'Ocurio un error por favor intentelo nuevamente.', MessageSeverity.error);
            }
          }
        }
        throw err;
      })
    );
  }
}
