<div class="wrap-view">
  <div class="row m-0" style="margin-top:-3%;">
    <div class="col-12 d-none d-md-block">
      <div class="row">
        <div class="col-md-4" style="padding:0">
          <img src="/assets/images/Chispas 1.png" />
        </div>
        <div class="col-md-4" style="height:20px;"></div>
        <div class="col-md-4">
          <img src="/assets/images/Chispas 2.png" />
        </div>
      </div>
    </div>
  </div>
  <div class="company-title">
    <div class="title-maestro">
      <span>Parámetros - Comisiones</span>
    </div>
    <div class="box-white container-fluid m-1" style="padding: 20px;">
      <label>
        <strong>Resultado</strong>
      </label>
      <div class="row m-1">
        <div class="col-12 text-right">
          <!--          <button class="form-control" style="width: 50px;margin-right: 10px;"-->
          <!--                  type="button" class="button-general" title="Buscar" (click)="getData()">-->
          <!--            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"-->
          <!--                 fill="currentColor" class="bi bi-search"-->
          <!--                 viewBox="0 0 16 16">-->
          <!--              <path-->
          <!--                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>-->
          <!--            </svg>-->
          <!--          </button>-->
          <button class="form-control" style="width: 50px;margin-right: 10px;" type="button" class="button-general"
            title="Exportar" (click)="exportar()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download"
              viewBox="0 0 16 16">
              <path
                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
              <path
                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
          </button>
          <button class="form-control" style="width: 50px" type="button" class="button-general" title="Nuevo"
            (click)="Nuevo()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-file-earmark-plus-fill" viewBox="0 0 16 16">
              <path
                d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM8.5 7v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 1 0z" />
            </svg>
          </button>
        </div>
      </div>
      <div class="grid-control-custom">
        <ngx-datatable class="material colored-header sm table table-hover" [rows]="rows" [columnMode]="'force'"
          [footerHeight]="40" [headerHeight]="60" [rowHeight]="50" #table [externalPaging]="true"
          [count]="page.totalElements??0" [offset]="page.pageNumber??0" [limit]="page.size" (page)="setPage($event)"
          [messages]="{ emptyMessage: 'No records found', totalMessage: 'total' }">
          <ngx-datatable-column name="Id" [width]="50" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span>{{row.id}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Tipo de Comisión" [width]="50" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span>{{row.commissionType}}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Generico/Bilateral" [width]="50" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span>{{row.genericBilateralFlag}}</span>
            </ng-template>

          </ngx-datatable-column>

          <ngx-datatable-column name="FeeCode" [width]="50" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span>{{row.feeCode}}</span>
            </ng-template>

          </ngx-datatable-column>

          <ngx-datatable-column name="ParticipantCode" [width]="50" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span>{{row.participantCode}}</span>
            </ng-template>

          </ngx-datatable-column>

          <ngx-datatable-column name="Moneda" [width]="50" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span>{{row.currency}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Tipo de Plaza" [width]="50" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span>{{row.plazaType}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Tipo de Cliente" [width]="50" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span>{{row.clientType}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Rango desde" [width]="50" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span>{{row.amountRangeFrom}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Rango hasta" [width]="50" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span>{{row.amountRangeTo}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="%" [width]="50" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span>{{row.percentage|number:'1.2-2'}}%</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Min" [width]="50" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span>{{row.min|number:'1.2-2'}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Max" [width]="50" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span>{{row.max|number:'1.2-2'}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="FlatFee" [width]="50" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span>{{row.flatFee}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="F.I.V" [width]="50" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span>{{row.effectiveStartDate|date:'dd/MM/yyyy'}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="F.F.V" [width]="50" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span>{{row.effectiveEndDate|date:'dd/MM/yyyy'}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="" [width]="80" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <button class="btn btn-xs" (click)="EditRecord(row)">
                <i class="fa fa-edit" aria-hidden="true" style="color:#B25BC2;" title="Editar"></i>
              </button>
              <button class="btn btn-xs" (click)="OpenQuestionDeleteRecord(row)">
                <i class="fa fa-trash-o" aria-hidden="true" style="color:#B25BC2;" title="Eliminar">
                </i>
              </button>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
              let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
              <div class="page-count">{{ rowCount.toLocaleString() }} elements</div>
              <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                [pagerNextIcon]="'datatable-icon-skip'" [page]="page.pageNumber??0" [size]="page.size??0"
                [count]="page.totalElements??0" class="datatable-pager" (change)="table.onFooterPage($event)">
              </datatable-pager>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>
<app-loading *ngIf="loading"></app-loading>
<div class="modal fade" bsModal [config]="{ backdrop: 'static', keyboard: false,animated:false}"
  #modalcommissions="bs-modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content modal-company-add">
      <div class="modal-header">
        <h5 class="modal-title pull-left">{{titleModal}}</h5>
      </div>
      <div class="modal-body text-left">
        <form class="form-cliente" autocomplete="offs" [formGroup]="regcommissionsForm">
          <div class="row">
            <div class="form-group col-sm-6">
              <label class="subtitle-modal">id</label>
              <input class="form-control" type="text" formControlName="id" />
            </div>
            <div class="form-group col-sm-6">
              <label class="subtitle-modal">Tipo de Comisión</label>
              <div class="form-check" *ngFor="let item of listComissionType">
                <label style="margin-left: 10px;">
                  <input class="form-check-input" type="radio" [value]="item.code" formControlName="commissionTypeCode">
                  <span>{{item.name}}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-6">
              <label class="subtitle-modal">Generico/Bilateral</label>
              <div class="form-check" *ngFor="let item of listBilateral">
                <label style="margin-left: 10px;">
                  <input class="form-check-input" type="radio"  [value]="item.code" formControlName="genericBilateralFlag">
                  <span>{{item.name}}</span>
                </label>
              </div>
            </div>

            <div class="form-group col-sm-6">
              <label class="subtitle-modal">FlatFee</label>
              <input class="form-control" type="text" formControlName="flatFee" />
            </div>

          </div>

          <div class="row">
            <div class="form-group col-sm-6">
              <label class="subtitle-modal">FeeCode</label>
              <input class="form-control" type="text" formControlName="feeCode" />
            </div>

            <div class="form-group col-sm-6">
              <label class="subtitle-modal">ParticipantCode</label>
              <input class="form-control" type="text" formControlName="participantCode"/>
            </div>
          </div>


          <div class="row">
            <div class="form-group col-sm-6">
              <label class="subtitle-modal">Moneda</label>
              <div class="form-check" *ngFor="let item of listCurrency">
                <label style="margin-left: 10px;">
                  <input class="form-check-input" type="radio" [value]="item.id" formControlName="currencyCode">
                  <span>{{item.iso}}</span>
                </label>
              </div>
            </div>
            <div class="form-group col-sm-6">
              <label class="subtitle-modal">Tipo de cliente</label>
              <div class="form-check" *ngFor="let item of listClientType">
                <label style="margin-left: 10px;">
                  <input class="form-check-input" type="radio" [value]="item.code" formControlName="clientTypeCode">
                  <span>{{item.name}}</span>
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-sm-6">
              <label class="subtitle-modal">Rango desde </label>
              <input class="form-control" type="text" formControlName="amountRangeFrom" />
            </div>

            <div class="form-group col-sm-6">
              <label class="subtitle-modal">Rango hasta</label>
              <input class="form-control" type="text" formControlName="amountRangeTo"/>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-sm-6">
              <label class="subtitle-modal">Tipo plaza</label>
              <select class="form-select form-select-sm" formControlName="plazaTypeCode">
                <option [value]="-1">Seleccionar</option>
                <option *ngFor="let item of listPlazaType" [value]="item.code">{{item.name}}</option>
              </select>
            </div>
            <div class="form-group col-sm-6">
              <label class="subtitle-modal">Porcentaje</label>
              <div class="input-group input-group-sm mb-3">
                <input type="text" [inputMask]="amountInputMask" class="form-control" formControlName="percentage">
                <span class="input-group-text" id="inputGroup-sizing-sm">%</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-sm-6">
              <label class="subtitle-modal">Mínimo</label>
              <input class="form-control" [inputMask]="amountInputMask" type="text" formControlName="min" />
            </div>
            <div class="form-group col-sm-6">
              <label class="subtitle-modal">Máximo</label>
              <input class="form-control" [inputMask]="amountInputMask" type="text" formControlName="max" />
            </div>
          </div>

          <div class="row">
            <div class="form-group col-sm-6">
              <label class="subtitle-modal">Vigencia</label>
              <input type="text" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [minDate]="minDate" bsDatepicker
                formControlName="effectiveStartDate" autocomplete="off" class="form-control" />
            </div>
            <div class="form-group col-sm-6">
              <label class="subtitle-modal">Hasta</label>
              <input type="text" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" autocomplete="off"
                formControlName="effectiveEndDate" class="form-control" />
            </div>


          </div>
        </form>
      </div>
      <div class="modal-footer justify-content-evenly">
        <button type="button" class="button-text d-none d-md-block" style="width: 180px !important;" title="Cancelar"
          (click)="closeModal()">Cancelar
        </button>
        <button class="button-general d-none d-md-block" style="width: 180px !important;" title="Confirmar"
          (click)="SaveCommissions()">Confirmar
        </button>
        <div class="col-6 m-0 ps-2 d-block d-md-none">
          <button type="button" class="button-text w-100" title="Cancelar">Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" bsModal [config]="{ backdrop: 'static', keyboard: false,animated:false}"
  #ValidaDeleteChannelModal="bs-modal" tabindex="-1">
  <div class="modal-dialog modal-sm" style="top: 211px;">
    <div class="modal-content modal-logout">
      <div class="modal-body text-center">
        <div class="title-logout-modal">¿Está seguro de Eliminar el Registro?</div>
        <div class="row" style="padding-bottom: 22px;">
          <div class="offset-1 col-5">
            <button class="button-logout pull-right" (click)="HideValidateDeleteChannel()">Cancelar</button>
          </div>
          <div class="col-5">
            <button class="button-logout pull-left" (click)="DeleteChannel()">Aceptar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
