<div class="wrap-view">
  <div class="row m-0" style="margin-top:-3%;">
    <div class="col-12 d-none d-md-block">
      <div class="row">
        <div class="col-md-4" style="padding:0">
          <img src="/assets/images/Chispas 1.png"/>
        </div>
        <div class="col-md-4" style="height:20px;"></div>
        <div class="col-md-4">
          <img src="/assets/images/Chispas 2.png"/>
        </div>
      </div>
    </div>
  </div>
  <div class="company-title">
    <div class="title-maestro">
      <span>Maestro de oficinas (al {{ lastLoadDate|date:'dd/MM/yyyy HH:mm:ss'}})</span>
    </div>
    <div class="box-white container-fluid m-1" style="padding: 20px;">
      <div class="container" [formGroup]="formFilter">
        <div class="row m-1">
          <div class="col-1 form-group">
            <label>Entidad</label>
          </div>
          <div class="col-2">
            <select class="form-select form-select-sm"
                    formControlName="entity">
              <option [value]="-1">Todas</option>
              <option *ngFor="let item of listFinancialEntity"
                      value="{{item.id}}">
                {{item.name}}</option>
            </select>
          </div>
          <div class="col-1">
            <label>Oficina</label>
          </div>
          <div class="col-2">
            <select class="form-select form-select-sm"
                    formControlName="office">
              <option [value]="-1">Todas</option>
              <option *ngFor="let item of listOffice"
                      value="{{item.id}}">
                {{item.name}}</option>
            </select>
          </div>
          <div class="col-3">
            <input class="form-control" type="text"
                   formControlName="officeName"
                   placeholder="Nombre de oficina"/>
          </div>
          <div class="col-3">
            <input class="form-control"
                   formControlName="ubigeo"
                   type="text" placeholder="Ubigeo"/>
          </div>
        </div>
        <div class="row m-1 mt-3">
          <div class="col-12"style="text-align: right">
            <button class="form-control" style="width: 50px;margin-right: 10px;"
                    (click)="search()"
                    type="button" class="button-general" title="Buscar">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-search" viewBox="0 0 16 16">
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
              </svg>
            </button>
            <button class="form-control" style="width: 50px;margin-right: 10px;"
                    (click)="downloadExcel()"
                    type="button" class="button-general" title="Descargar Excel">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-download" viewBox="0 0 16 16">
                <path
                  d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                <path
                  d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
              </svg>
            </button>
            <input #file type="file" style="display:none"  name="Archivo"
                   (change)="uploadOfficeFile($event)" >
            <button class="form-control" style="width: 50px"
                    (click)="file.click()"
                    type="button" class="button-general" title="Cargar Archivo de Oficinas">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-upload" viewBox="0 0 16 16">
                <path
                  d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                <path
                  d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="grid-control-custom">
        <div>
          <ngx-datatable class="material colored-header sm table table-hover text-left"
                         [rows]="rows" [columns]="columns" [columnMode]="'force'" [footerHeight]="40"
                         [headerHeight]="60" [rowHeight]="30"
                         [limit]="10"></ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loading *ngIf="loading"></app-loading>
