import { SecurityService } from 'src/app/services/security.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppComponent } from 'src/app/app.component';
import { AppConstants } from '../../constants/app.constants';
import { LoginService } from '../../../services/login.service';
import { SessionService } from '../../services/session.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { UserPasswordModel } from 'src/app/models/security';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  modalTitle: string = AppConstants.TitleModal.CHANGE_PASSWORD;
  isNavRipley: boolean = false;
  bandejaActive: boolean = false;

  loginPath: string = 'login';
  loading: boolean = false;
  comentarioAnulacionForm: string = '';
  motivoAnulacionForm: string = '';
  isChangePassword = false;
  resetPasswordForm: FormGroup;
  @ViewChild('passwordModal') passwordModal: ModalDirective;
  @ViewChild('prototipoModal') prototipoModal: any;  //ModalDirective
  motivosAnulacion = [];
  equalsPassword: boolean = false;
  arrayMenu = [] as any[];
  userPasswordModel: UserPasswordModel = new UserPasswordModel();
  formErrors = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  }

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private appComponent: AppComponent,
    private alertaService: AlertService,
    private sessionService: SessionService,
    private securityService: SecurityService,
    private authService: LoginService) {
  }

  ngOnInit() {
    this.createForm();
    this.arrayMenu = JSON.parse(sessionStorage.getItem(AppConstants.Session.MENU)!);
  }

  ngAfterViewInit() {

    let openPopup: boolean = false
    if (sessionStorage.getItem(AppConstants.Session.CHANGEPASSWORD) === "1") {
      this.modalTitle = AppConstants.TitleModal.REQUIRED_CHANGE_PASSWORD;
      openPopup = true;
    }
    else if (sessionStorage.getItem(AppConstants.Session.EXPIREDPASSWORD) === "1") {
      this.modalTitle = AppConstants.TitleModal.EXPIRED_PASSWORD;
      openPopup = true;
    }

    if (openPopup) {
      this.isChangePassword = true;
      setTimeout(() => {
        this.passwordModalShow();
      }, 1000)
    }
  }

  createForm() {
    this.resetPasswordForm = this.formBuilder.group({
      userId: '',
      currentPassword: ['', [this.noWhiteSpaceValidator]],
      newPassword: ['', [this.noWhiteSpaceValidator,
      this.passwordValidator,
      Validators.minLength(8)]],
      confirmNewPassword: ['', [this.noWhiteSpaceValidator,
      this.passwordValidator,
      Validators.minLength(8)]]
    })
  }

  passwordModalShow() {
    this.resetPasswordForm.reset();
    this.cleanFormErrors(this.formErrors);
    this.passwordModal.show();
  }

  public noWhiteSpaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').toString().trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'required': true };
  }

  public passwordValidator(control: FormControl) {
    const passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.#])[A-Za-z\d@$!%*?&.#]{8,}$/;
    if (!passwordRegexp.test(control.value)) {
      return { "pattern": true };
    }
    return null;
  }

  public cleanFormErrors(formErrors) {
    for (const field in formErrors) {
      formErrors[field] = '';
    }
  }
  showNav() {
    this.isNavRipley = !this.isNavRipley;
  }

  hideNav() {
    this.isNavRipley = !this.isNavRipley;
  }

  goToRoute(route: string) {
    route == 'salir' ? this.logout() : this.router.navigateByUrl(route);
  }

  logout() {
    this.prototipoModal.show();
  }

  confirmLogout() {
    this.authService.logout();
    this.sessionService.logout();
    this.appComponent.navigateTo('', this.loginPath);
  }

  cancelLogout() {
    this.prototipoModal.hide();
  }

  showTransaction() {
    let result = false;
    return result;
  }

  changePassword() {
    let model = this.resetPasswordForm.value;

    if (model.newPassword != model.confirmNewPassword) {
      this.equalsPassword = true;
      this.formErrors.newPassword = AppConstants.Messages.PASSWORDS_INCORRECTS;
      this.formErrors.confirmNewPassword = AppConstants.Messages.PASSWORDS_INCORRECTS;
      return;
    }
    else {

      this.equalsPassword = false;
      this.formErrors['newPassword'] = '';
      this.formErrors['confirmNewPassword'] = '';
    }

    if (!this.resetPasswordForm.valid) {
      this.formErrors = this.validateForm(this.resetPasswordForm, this.formErrors, AppConstants.LoginErrorMessages, false);
      return;
    }
    this.loading = true;

    model.userId = sessionStorage.getItem(AppConstants.Session.USERID);
    this.userPasswordModel.setAll(model);
    this.securityService.updatePassword(this.userPasswordModel).subscribe(
      (response) => {

        this.alertaService.showMessage(AppConstants.TitleModal.CHANGE_PASSWORD, AppConstants.Messages.CHANGE_PASSWORD_CORRECT, MessageSeverity.success);
        this.appComponent.navigateTo('', this.loginPath);
        this.loading = false;
      },
      (error) => {
        let message = this.getErrorMessages(error);
        this.alertaService.showMessage(AppConstants.TitleModal.CHANGE_PASSWORD, message, MessageSeverity.error);
        this.loading = false;
      }
    )
  }

  public validateForm(formToValidate: any, formErrors: any, messages: any, checkDirty?: boolean) {
    const form = formToValidate;

    for (const field in formErrors) {
      var messagesT = messages[field];
      if (field) {
        const control = form.get(field);
        if (control.hasOwnProperty('controls')) {
          var formErrorsT = formErrors[field];
          control['controls'].forEach(item => {
            this.validateForm(item, formErrorsT, messagesT);
          });
        }
        else {
          if (control && !control.valid) {
            if (!checkDirty || (control.dirty || control.touched)) {
              for (const key in control.errors) {
                if (key && key !== 'invalid_characters') {
                  formErrors[field] = '';
                  formErrors[field] = formErrors[field] || messagesT[key];
                } else {
                  formErrors[field] = formErrors[field] || messagesT[key](control.errors[key]);
                }
              }
            }
          }
          else {
            formErrors[field] = '';
          }
        }
      }
    }
    return formErrors;
  }
  public getErrorMessages(errorMessages) {
    let messages: string = '';

    if (errorMessages.error.messages != undefined) {
      messages = errorMessages.error.messages;
    } else {
      for (var field in errorMessages.error) {
        messages += `${errorMessages.error[field]} `;
      }
    }
    return messages;
  }
}