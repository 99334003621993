<div class="wrap-view">
  <div class="row m-0" style="margin-top:-3%;">
    <div class="col-12 d-none d-md-block">
      <div class="row">
        <div class="col-md-4" style="padding:0">
          <img src="/assets/images/Chispas 1.png"/>
        </div>
        <div class="col-md-4" style="height:20px;"></div>
        <div class="col-md-4">
          <img src="/assets/images/Chispas 2.png"/>
        </div>
      </div>
    </div>
  </div>
  <div class="company-title">
    <div class="title-maestro">
      <span>Consulta de Transferencia - Salida</span>
    </div>
    <div class="box-white container-fluid m-1" style="padding: 20px;">
      <div class="container" [formGroup]="formFilter">
        <div class="row m-1">
          <div class="col-1 form-group">
            <label>Fecha:</label>
          </div>
          <div class="col-2">
            <input type="text" bsDatepicker
                   formControlName="startDate" class="form-control"/>
          </div>
          <div class="col-1 form-group">
            <label>Hasta:</label>
          </div>
          <div class="col-2">
            <input type="text" bsDatepicker
                   formControlName="endDate" class="form-control"
            />
          </div>
          <div class="col-1">
            <label>Etapa</label>
          </div>
          <div class="col-2">
            <select class="form-select form-select-sm" formControlName="state">
              <option [value]="-1" selected>Todos</option>
              <option *ngFor="let item of listStatus" value="{{item.id}}">
                {{item.nombre}}</option>
            </select>
          </div>
          <div class="col-3">
            <input class="form-control" type="text" placeholder="Referencia"
                   formControlName="reference"/>
          </div>
        </div>
        <div class="row m-1">
          <div class="col-1">
            <label>Tipo Operación.</label>
          </div>
          <div class="col-2">
            <select class="form-select form-select-sm" formControlName="transactionType">
              <option [value]="-1">Todos</option>
              <option *ngFor="let item of listOperationType" value="{{item.code}}">
                {{item.name}}</option>
            </select>
          </div>
          <div class="col-1">
            <label>Entidad.</label>
          </div>
          <div class="col-2 form-group">
            <select class="form-select form-select-sm" formControlName="entity">
              <option [value]="-1">Todos</option>
              <option *ngFor="let item of listFinancialEntity"
                      value="{{item.id}}">
                {{item.name}}</option>
            </select>
          </div>

          <div class="col-1">
            <label>Tipo Doc.</label>
          </div>
          <div class="col-1">
            <select class="form-select form-select-sm"
                    formControlName="documentType"
                    aria-label=".form-select-sm example">
              <option [ngValue]="-1">Todos</option>
              <option *ngFor="let item of listTypeDocument"
                      value="{{item.code}}">
                {{item.description}}</option>
            </select>
          </div>
          <div class="col-2">
            <input class="form-control" type="text" placeholder="Originante"
                   formControlName="documentNumberOrigin"/>
          </div>
          <div class="col-2">
            <input class="form-control" type="text" placeholder="Beneficiario"
                   formControlName="documentNumberDestiny"/>
          </div>

          <div class="col-1">
            <label>Trace</label>
          </div>
          <div class="col-2">
            <input class="form-control" type="text" placeholder=""
                   formControlName="trace"/>
          </div>
          <div class="col-1">
            <label>Canal</label>
          </div>
          <div class="col-2">
            <input class="form-control" type="text" placeholder=""
                   formControlName="channel"/>
          </div>
        </div>
        <div class="row m-1">
          <div class="col-10">
          </div>

          <div class="col-1">
            <button class="form-control" type="button" class="button-general"
                    (click)="buscar()"
                    title="Buscar">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
                   viewBox="0 0 16 16">
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
              </svg>
            </button>
          </div>
          <div class="col-1">
            <button class="form-control"
                    type="button" class="button-general"
                    (click)="downloadExcel()"
                    title="Exportar">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download"
                   viewBox="0 0 16 16">
                <path
                  d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                <path
                  d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="grid-control-custom">
        <div>
          <ngx-datatable
            class="material colored-header sm table table-hover"
            [rows]="rows"
            [columns]="columns"
            [columnMode]="'force'"
            [footerHeight]="40"
            [headerHeight]="60"
            [scrollbarH]="true"
            [rowHeight]="30"
            #table
            [externalPaging]="true"
            [count]="page.totalElements??0"
            [offset]="page.pageNumber??0"
            [limit]="page.size"
            (page)="setPage($event)"
            [externalSorting]="true"
            (sort)="onSort($event)"
            [messages]="{ emptyMessage: 'No records found', totalMessage: 'total' }">
            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
              >
                <div class="page-count">{{ rowCount.toLocaleString() }} elements</div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'"
                  [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'"
                  [page]="page.pageNumber??0"
                  [size]="page.size??0"
                  [count]="page.totalElements??0"
                  class="datatable-pager"
                  (change)="table.onFooterPage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>

          <ng-template #dateTemplate let-row="row" let-value="value">
            {{row.creationDate|date:'dd/MM/yyyy HH:mm:ss'}}
          </ng-template>
          <ng-template #moneyTemplate let-row="row" let-value="value">
            {{value|number:'1.2-2'}}
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loading *ngIf="loading"></app-loading>

<!-- <div class="wrap-view">
  <div class="row m-0" style="margin-top:-3%;">
    <div class="col-12 d-none d-md-block">
      <div class="row">
        <div class="col-md-4" style="padding:0">
          <img src="/assets/images/Chispas 1.png" />
        </div>
        <div class="col-md-4" style="height:20px;"></div>
        <div class="col-md-4">
          <img src="/assets/images/Chispas 2.png" />
        </div>
      </div>
    </div>
  </div>


  <div class="company-title">
    <div class="title-maestro">
      <span>Consulta de Transferencia - Salida</span>
    </div>
    <div class="box-white container-fluid m-1" style="padding: 20px;">
      <div class="container" [formGroup]="formFilter">
        <div class="row m-1">
          <div class="col-1 form-group">
            <label>Fecha:</label>
          </div>
          <div class="col-2">
            <input type="text" bsDatepicker formControlName="startDate" class="form-control" />
          </div>
          <div class="col-1 form-group">
            <label>Hasta:</label>
          </div>
          <div class="col-2">
            <input type="text" bsDatepicker formControlName="endDate" class="form-control" />
          </div>
          <div class="col-1">
            <label>Estado</label>
          </div>
          <div class="col-2">
            <select class="form-select form-select-sm" formControlName="state">
              <option [value]="-1" selected>Todos</option>
              <option value="1">Todos</option>
            </select>
          </div>
          <div class="col-3">
            <input class="form-control" type="text" placeholder="Referencia" formControlName="reference" />
          </div>
        </div>
        <div class="row m-1">
          <div class="col-1">
            <label>Tipo Transacción.</label>
          </div>
          <div class="col-2">
            <select class="form-select form-select-sm" formControlName="transactionType">
              <option [value]="-1">Todos</option>
              <option *ngFor="let item of listOperationType" value="{{item.code}}">
                {{item.name}}</option>
            </select>
          </div>
          <div class="col-1">
            <label>Entidad.</label>
          </div>
          <div class="col-2 form-group">
            <select class="form-select form-select-sm" formControlName="entity">
              <option [value]="-1">Todos</option>
              <option *ngFor="let item of listFinancialEntity" value="{{item.id}}">
                {{item.name}}</option>
            </select>
          </div>

          <div class="col-1">
            <label>Tipo Doc.</label>
          </div>
          <div class="col-1">
            <select class="form-select form-select-sm" formControlName="documentType"
              aria-label=".form-select-sm example">
              <option [ngValue]="-1">Todos</option>
              <option *ngFor="let item of listTypeDocument" value="{{item.code}}">
                {{item.description}}</option>
            </select>
          </div>
          <div class="col-2">
            <input class="form-control" type="text" placeholder="Originante" formControlName="documentNumberOrigin" />
          </div>
          <div class="col-2">
            <input class="form-control" type="text" placeholder="Beneficiario"
              formControlName="documentNumberDestiny" />
          </div>
        </div>
        <div class="row m-1">
          <div class="col-10">
          </div>

          <div class="col-1">
            <button class="form-control" type="button" class="button-general" (click)="buscar()" title="Buscar">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
                viewBox="0 0 16 16">
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </button>
          </div>
          <div class="col-1">
            <button class="form-control" type="button" class="button-general" (click)="buscar()" title="Buscar">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download"
                viewBox="0 0 16 16">
                <path
                  d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                <path
                  d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="grid-control-custom">
        <div>
          <ngx-datatable class="material colored-header sm table table-hover" [rows]="rows" [columns]="columns"
            [columnMode]="'force'" [footerHeight]="40" [headerHeight]="60" [scrollbarH]="true" [rowHeight]="30"
            [limit]="10"></ngx-datatable>

          <ng-template #dateTemplate let-row="row" let-value="value">
            {{row.creationDate|date:'dd/mm/yyyy HH:mm:ss'}}
          </ng-template>
          <ng-template #moneyTemplate let-row="row" let-value="value">
            {{value|number:'1.2-2'}}
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div> -->
