import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import { Utilities } from '../shared/helpers/util';

@Injectable({
  providedIn: 'root'
})
export class TransferOrderOutService {

  constructor(private _http: HttpClient) {
  }

  private url: string = environment.url_base;
  
  GetStatus(): Observable<any[]> {
    return this._http.get<any[]>(`${this.url}/TransferOrderOut/status`,);
  }
  GetALL(filter): Observable<any> {
    const params = Utilities.createHttpParams(filter);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}/TransferOrderOut`, {
      params,
      headers
    });
  }
  GetALLForFraud(filter): Observable<any> {
    const params = Utilities.createHttpParams(filter);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}/TransferOrderOut/Fraud`, {
      params,
      headers
    });
  }

  aprove(row): Observable<any> {
    return this._http.post(`${this.url}/TransferOrderOut/Aprove/${row.transferOrderOutId}`, row);
  }
  decline(row): Observable<any> {
    return this._http.post(`${this.url}/TransferOrderOut/Decline/${row.transferOrderOutId}`, row);
  }

  
  getExport(filter: any): Observable<HttpResponse<Blob>> {
    const params = Utilities.createHttpParams(filter);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}/TransferOrderOut/export`,
      {
        headers: headers,
        params: params,
        responseType: 'blob',
        observe: 'response'
      });
  }

}
