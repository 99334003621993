import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConciliationStatusService {

  constructor(private _http: HttpClient) { }

  private url: string = environment.url_base;

  GetALL(): Observable<any> {
    return this._http.get(`${this.url}/ConciliationStatus`);
  }

}
