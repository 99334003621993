import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserPasswordModel } from '../models/security';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(private _http: HttpClient) { }

  private url: string = environment.url_base;
  private urlOnboarding: string = environment.url_base_onboarding;

  updatePassword(userPasswordParameters: UserPasswordModel): Observable<UserPasswordModel> {
    return this._http.put<UserPasswordModel>(`${this.urlOnboarding}/api/User/updatePassword`, userPasswordParameters);
  }
  
  validateCaptcha(token: string): Observable<boolean> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*'
      })
    };
    return this._http.post<boolean>(`${this.urlOnboarding}/api/Verification/captcha`, JSON.stringify(token), httpOptions);
  }

  getMenu() {
    return this._http.get<any[]>(`${this.urlOnboarding}/api/Parameter/GetMenu`);
  }

}
