import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SessionService } from '../../shared/services/session.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { LoginService } from 'src/app/services/login.service';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { AppConstants, LoginMessages } from 'src/app/shared/constants/app.constants';
import { SecurityService } from 'src/app/services/security.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  token: string = '';
  loading: boolean = false;
  publicKey: string = '';
  @Output() navigateToEvent = new EventEmitter<string>();

  passwordChanged = false;

  loginForm: FormGroup = new FormGroup({});
  pswForm: FormGroup = new FormGroup({});

  // AUDIT PARAMS
  deviceInfo: any;
  ipAddress = '';
  location = '';
  private httpClient: HttpClient;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
    private securityService: SecurityService,
    handler: HttpBackend,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.httpClient = new HttpClient(handler);
  }

  ngOnInit() {
    console.log('a');
    this.createForm();
  }

  createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  logueo() {
    if (this.loginForm.valid) {
      //this.validateCaptcha();
      this.login();
    } else {
      this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE, AppConstants.Messages.PARAMETER_EMPTY, MessageSeverity.error);
    }
  }

  login() {
    this.loading = true;
    const usuario = this.loginForm.value['username'];
    const password = this.loginForm.value['password'];
    this.loginService.GetAuthTokenAsync(usuario, password).subscribe({
      next: (req) => {
        this.sessionService.create(req);
        this.getMenu();
      },
      error: (error) => {
        this.loading = false;
        var message: string = '';
        switch (error.error.error) {
          case '1':
            message = AppConstants.Messages.USER_DOESNT_EXIST;
            break;
          case '2':
            message = AppConstants.Messages.PASSWORD_DOESNT_CORRECT;
            break;
          case '3':
            message = AppConstants.Messages.USER_IS_INACTIVE;
            break;
          case '5':
            message = AppConstants.Messages.USER_IS_LOCKED;
            break;
        }
        this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE, message, MessageSeverity.error);

      },
    });
  }

  validateCaptcha() {
    this.loading = true;

    this.recaptchaV3Service.execute('login')
      .subscribe((token) => {
        this.loading = false;
        this.handleRecaptchaExecute(token);
      }, (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE,
          AppConstants.Messages.RECAPTCHA_ERROR, MessageSeverity.error);
      });
  }

  handleRecaptchaExecute(token: string) {
    if (token == undefined || token == null || token == '') {
      this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE, LoginMessages.ACTIVIDAD_SOSPECHOSA, MessageSeverity.error);
    }

    this.securityService.validateCaptcha(token)
      .subscribe((response: boolean) => {
        if (response) {
          this.login();
        } else {
          this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE, LoginMessages.ACTIVIDAD_SOSPECHOSA, MessageSeverity.error);
        }
      }, (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE, AppConstants.Messages.RECAPTCHA_ERROR, MessageSeverity.error);
      });
  }

  navigateTo(route: string) {
    this.navigateToEvent.next(route);
  }

  getMenu() {
    this.loading = true;
    this.securityService.getMenu()
      .subscribe((response) => {
        sessionStorage.setItem(
          AppConstants.Session.MENU,
          JSON.stringify(response)
        );
        this.navigateTo('home');
        this.loading = false;
      }, (error: HttpErrorResponse) => {
        this.loading = false;
      });
  }
}
