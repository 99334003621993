import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Utilities} from "../shared/helpers/util";

@Injectable({
  providedIn: 'root'
})
export class LimitchanneloperationService {

  constructor(private _http: HttpClient) {
  }

  private url: string = environment.url_base;

  GetALL(filter: any): Observable<any> {
    const params = Utilities.createHttpParams(filter);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}/LimitChannelOperation`, {params, headers: headers});
  }

  getExportExcel(): Observable<HttpResponse<Blob>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}/LimitChannelOperation/download`,
      {
        headers: headers,
        responseType: 'blob',
        observe: 'response'
      });
  }

  create(data: any): Observable<any> {

    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(`${this.url}/LimitChannelOperation`,
    data, {
        headers: headers
      });
  }

  delete(id: number): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.delete(`${this.url}/LimitChannelOperation/${id}`, {
      headers: headers
    });
  }

  modify(data: any): Observable<any> {

    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(`${this.url}/LimitChannelOperation`,
    data,{
        headers: headers
      });
  }
}
