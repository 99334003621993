import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

import { MenuComponent } from './components/menu/menu.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { ConciliationComponent } from './components/conciliation/conciliation.component';
import { ParametersComponent } from './components/parameters/parameters.component';
import { RegChannelsComponent } from './components/reg-channels/reg-channels.component';
import { RegLimitChannelsComponent } from './components/reg-limit-channels/reg-limit-channels.component';
import { RegCommissionsComponent } from './components/reg-commissions/reg-commissions.component';
import { RegOfficesComponent } from './components/reg-offices/reg-offices.component';
import { ManagmentFraudComponent } from './components/managment-fraud/managment-fraud.component';
import { ConsultTransferComponent } from './components/consult-transfer/consult-transfer.component';
import { ConsultTransferOutComponent  } from './components/consult-transfer-out/consult-transfer-out.component';

const routes: Routes = [

  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent},
  { path: 'home', component: HomeComponent},
  { path: 'conciliation', component: ConciliationComponent, canActivate:[AuthGuard] },
  { path: 'parameters', component: ParametersComponent, canActivate:[AuthGuard] },
  { path: 'reg-channels', component: RegChannelsComponent, canActivate:[AuthGuard] },
  { path: 'reg-limit-channels', component: RegLimitChannelsComponent, canActivate:[AuthGuard] },
  { path: 'reg-commissions', component: RegCommissionsComponent, canActivate:[AuthGuard] },
  { path: 'reg-offices', component: RegOfficesComponent, canActivate:[AuthGuard] },
  { path: 'managment-fraud', component: ManagmentFraudComponent, canActivate:[AuthGuard] },
  { path: 'consult-transfer', component: ConsultTransferComponent, canActivate:[AuthGuard] },
  { path: 'consult-transfer-out', component: ConsultTransferOutComponent, canActivate:[AuthGuard] }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
