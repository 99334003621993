<div class="fondo-home">
    <img src="../../../assets/images/FondoHome.png" />
  </div>
  <div>
    <div class="row m-0 justify-content-center mt-4">
      <div class="col-8 text-center">
        <label class="lbl-home">Bienvenido(a)</label>
      </div>
    </div>
    <div class="row m-0 justify-content-center mt-4">
      <div class="col-12 text-center">
        <label class="lbl-home-b">Web Administrativa</label>
      </div>
    </div>
  </div>



