// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url_base_onboarding:"https://service.onboarding.dev.bancoripley.com.pe",
  url_base: 'https://tinadminservices.dev.bancoripley.com.pe/admin',
  url_ef: 'https://apisecfraud.dev.bancoripley.com.pe/identity',
  ip:'https://api.ipify.org/?format=json',
  reCaptchaKey : '6LePgeIUAAAAAHOZ3ObQaVHcgCzEimjomrXdPIZ1'
};

  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.


  // si quieres levantar el proyecto en dev

  /*export const environment = {
    production: false,
    url_base_onboarding:"https://service.onboarding.dev.bancoripley.com.pe",
    url_base: 'https://tinadminservices.bancoripley.com.pe/admin',
    url_ef: 'https://apisecfraud.bancoripley.com.pe/identity',
    ip:'https://api.ipify.org/?format=json',
    reCaptchaKey : '6LePgeIUAAAAAHOZ3ObQaVHcgCzEimjomrXdPIZ1'
  };*/
